body {
  background: $white;
  color: $davy-grey;
  font-family: $regular-font;
  font-size: 1.375em;
  overflow-x: hidden;
}

html {
  @include media-breakpoint-down(lg) {
    // overflow-x: hidden;
  }
}

html,
body {
  height: 100%;
  max-width: 100vw;
}

main{
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
.root-container.bg-plus {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background: url("/assets/images/globe_white.svg");
    width: 100%;
    height: 100%;
    @include right(-97%);
    background-repeat: no-repeat;
    bottom: -2rem;
    opacity: 1;
    z-index: -1;
    -webkit-filter: invert(100%);
    filter: invert(10%);
    @media (max-width: 1680px) {
      bottom: -50px;
    }
    @include media-breakpoint-down(md) {
      width: 200px;
      height: 200px;
      bottom: -160px;
      @include right(-88px);
    }
  }
}
#main_root {
  overflow: hidden;
}

.container {
  @media  only screen and (max-width : 1700) {
    @include p-left(0px);
    @include p-right(0px)
  }



  @include desktop-xxl{
 
    @include p-left(20px);
    @include p-right(20px);
  }

  @include mobile {
    max-width: 100%;
    @include p-left(30px);
    @include p-right(30px);
  }
}
.over-hidden {
  overflow: hidden;
}
::placeholder {
  color: $white;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: $white;
}

.text-wrapper {
  max-width: 1364px;
  margin: 0 auto;
  p{
    strong{
      color: $may-green;
    }
  }
}
img {
  width: 100%;
}

h3 {
  font-size: 1.8rem;
  padding-bottom: 1em;
  font-family: $light-font;
  @include desktop-xxl{
    font-size: 1.5rem;
  }
  @include mobile{
      font-size: 1.4rem;
  }
}

a {
  color: $spanish-gray;
  text-decoration: none;
  transition: opacity 0.9s;
  display: flex;
  align-items: center;
  &:hover {
    color: $spanish-gray;
    text-decoration: underline;
  }
}

ul {
  li {
    font-family: $light-font;
    @include media-breakpoint-down(lg) {
      font-size: 12px;
    }
  }
}

.spacer {
  padding-bottom: 58px;
  @include media-breakpoint-down(lg) {
    padding-bottom: 29px;
  }
}

p {
  font-family: $light-font;
  line-height: 1.4em;
  font-size: 1.35rem;
  color: #707070;
  @include media-breakpoint-down(lg) {
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  b {
    font-family: "DinProBold";
  }
  a {
    color: $may-green;
    display: inline-block;
    text-decoration: underline;
    &:hover {
      color: $may-green;
    }
  }
}

video {
  width: 100%;
}

/** Section Title Style **/
.section-title {
  position: relative;
  // padding-bottom: 80px;
  padding-bottom: 10px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 24px;
  }
  @include desktop-xxl{
    // padding-bottom: 1rem;
    padding-bottom: 2rem;
  }
  @include desktop-xl{
    padding-bottom: 3rem;
  }
  @include mobile{
    padding-bottom: 2rem;
  }
  .title {
    background: linear-gradient(95deg, #2281c1 0%, #2382b5 1%, #2e8e5b 16%, #339337 100%) 0% 0% no-repeat padding-box;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 2.5rem;
    font-family: "DinProBold";
    display: flex;
    flex: none;
    align-items: center;
    margin-top: -15px;
    @include p-right(30px);
    @include media-breakpoint-down(lg) {
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 16px;
    }

    position: relative;
    padding-bottom: 33px;
    display: flex;
  }

  .title.bg-pulse {
    .text {
      background: linear-gradient(95deg, #2281c1 0%, #2382b5 10%, #2e8e5b 72%, #339337 100%) 0% 0% no-repeat padding-box;
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      font-family: "DinProBold";
      display: flex;
      flex: none;
      align-items: center;
      margin-top: -15px;
      @include p-right(30px);
      font-size: 2.5rem;
      line-height: 1.2em;
      @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 30px;
      }
      @include desktop-xxl{
        margin-bottom: 1rem;
      }
      @media (min-width: 300px) and (max-width: 1000px) {
        margin-bottom: 20px;
      }
      @include mobile{
        margin-bottom: 0px;
      }
    }
    .pulse-img {
      width: 100%;
      height: 100%;
      background-position: right;
      @include media-breakpoint-down(lg) {
        height: 60px;
        @include left(29px);
        background-position-x: right;
        background-size: cover;
      }
      @include mobile{
        display: none;
      }
    }
    position: relative;
    margin-bottom: 0px;
    display: flex;
    @include desktop-xxl{
      margin-bottom: -40px;
    }
    @media (min-width: 300px) and (max-width: 1000px) {
      margin-bottom: 0;
    }
  }
  .sub-title {
    display: inline-block;
    font-family: $light-font;
    font-size: 1.35rem;
    line-height: 1.2em;
    display: block;
    max-width: 100%;
    margin-bottom: 2rem;
    @include media-breakpoint-down(lg) {
      font-size: 12px;
      line-height: 14px;
    }
    @include desktop-xxl{
      max-width: 100%;
    }
    @include desktop-xl{
      max-width: 100%;
    }
    @include mobile{
      margin-bottom: 1rem;
    }
  }
}

.page-titles-wrapper {
  .title {
    padding-bottom: 1%;
    
    // @include media-breakpoint-down(lg) {
    //   padding-bottom: 17px;
    // }
  }
}

/** Button styles solid & oulined **/
.btn {
  min-width: 4rem;
  color: #fff;
  padding: 0px 28px;
  height: 45px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  font-family: $light-font;
  display: inline-flex;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  align-items: center;
  outline: none;
  border-radius: 100px;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  font-family: $medium-font;
  
  @include media-breakpoint-down(lg) {
    padding: 10px 14px;
    font-size: 12px;
  }

  &.btn-back {
    padding: 0px 25px;
    font-family: $light-font;
    line-height: 1em;
    // background: $white-op0_34;
    background: #FFFFFF;
    color: #39838b;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
      padding: 0px 25px;
    }
    i {
      font-size: 16px;
      @include p-right(10px);
      margin-bottom: 3px;
      @include media-breakpoint-down(lg) {
        font-size: 8px;
      }
    }
    &:hover {
      text-decoration: underline;
      background: $teal-blue;
    }
  }

  &.btn-solid {
    border: 2px solid $dark-pastel-blue;
    background-color: $dark-pastel-blue;
    height: 45px;
    @include media-breakpoint-down(lg) {
      height: 30px;
      border: 1px solid $dark-pastel-blue;
    }
    &:hover {
      background-color:  $dark-pastel-blue;
      color: $white;
      text-decoration: underline;
    }
  }
  &.btn-outline {
    border: 1px solid $teal-blue;
    height: 2.5rem;
    background-color: transparent;
    color: $teal-blue;
    &.gray {
      color: $spanish-gray;
      border-color: $spanish-gray;
      &:hover {
        background-color: $dark-pastel-blue;
        color: $white;
        text-decoration: none;
        border-color: $dark-pastel-blue;
      }
      &.active {
        background-color: $dark-pastel-blue;
        border-color: $dark-pastel-blue;
        color: $white;
      }
    }

    &.blue {
      color: $dark-pastel-blue;
      border-color: $dark-pastel-blue;
      &:hover {
        background-color: $dark-pastel-blue;
        color: $white;
        text-decoration: none;
        border-color: $dark-pastel-blue;
      }
    }

    &.pastel-blue {
      color: $teal-blue;
      border-color: $teal-blue;
      &:hover {
        background-color: $teal-blue;
        color: $white;
        text-decoration: none;
        border-color: $teal-blue;
      }
    }

    &.green {
      color: $mughal-Green;
      border-color: $mughal-Green;
      &:hover {
        background-color: $mughal-Green;
        color: $white;
        text-decoration: none;
        border-color: $mughal-Green;
      }
    }

    &.bold {
      font-family: $bold-font;
    }
    @include media-breakpoint-down(lg) {
      height: 30px;
      border: 1px solid $teal-blue;
    }
  }
  &:hover {
    background-color: $teal-blue;
    color: $white;
    text-decoration: underline;
  }

  &.btn-v2 {
    height: 45px;
    @include media-breakpoint-down(lg) {
      height: 30px;
    }
    &.btn-solid {
      border-color: $teal-blue;
      background-color: $teal-blue;
      &:hover {
        background-color: $white;
        color: $teal-blue;
        text-decoration: underline;
      }
    }
  }
}


/*** Pagination Styles ***/
.paginations-section {
  padding: 8% 0;
  display: flex;
  justify-content: center;
  @include media-breakpoint-down(lg) {
    padding: 40px 0;
  }
  nav {
    display: flex;
    justify-content: center;
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      li {
        color: $spanish-gray-op0_4;
        @include p-right(38px);
        @include media-breakpoint-down(lg) {
          @include p-right(19px);
        }
        &:last-child {
          @include p-right(0);
        }
        a {
          color: #575756;
          font-size: 24px;
          font-family: $bold-font;
          line-height: 32px;
          @include media-breakpoint-down(lg) {
            font-size: 12px;
          }
          &.current {
            color: #4FA347;
            font-family: "DinNextLTBold", arial, serif;
            text-decoration: underline;
          }
          &:hover {
            color: $may-green;
            text-decoration: none;
          }
        }
        .current {
          color: #4FA347;
          font-family: "DinNextLTBold", arial, serif;
          text-decoration: underline;
        }
      }
    }
  }
}
.project-date{
  i{
    @include custom-left(5px);
  }
}
/** News card **/
.news-card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.875rem;
  margin: 0 auto;
  column-gap: 3.5%;
  row-gap: 6%;
  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.875rem;
    margin: 0 auto;
    // column-gap: 10px;
  }
  .news-card {
    margin-bottom: 20px !important;
    & .description {
      color: $davy-grey;
    }

    &:hover {
      cursor: pointer;
      & .description {
        color: $may-green;
        text-decoration: underline !important;
      }
    }

    &:hover img {
      -webkit-transform: scale(1.03);
      transform: scale(1.03);
    }
    .image-section {
      margin-bottom: 4%;
      overflow: hidden;
      transition: 0.4s ease;
      border-radius: 20px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }
      img {
        border-radius: 20px;
        width: 100%;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        @include media-breakpoint-down(lg) {
          border-radius: 12px;
        }
      }
    }
    .title-date {
      display: flex;
      justify-content: space-between;
      // align-items: flex-start;
      flex-wrap: wrap-reverse;
      margin-bottom: 23px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 12px;
      }
      .title {
        font-family: "DinProBold";
        min-width: 130px;
        font-size: 18px;
        line-height: 1.2em;
        color: $spanish-gray;
        @include custom-right(10px);
        flex: 1 1 0;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }
      .date {
        color: $spanish-gray;
        font-size: 18px;
        margin-left: auto;
        line-height: 22px;
        width: fit-content;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }
    }
    .description {
      font-family:$regular-font;
      font-size: 1.4rem;
      margin-bottom: 43px;
      
      @include media-breakpoint-down(lg) {
        margin-bottom: 22px;
      }
    }
  }
}

/** Content footer **/
.content-footer {
  display: flex;
  justify-content: space-between;
  margin: 85px 0;
  padding: 21px 0;
  border-top: 1px dotted $teal-blue;
  border-bottom: 1px dotted $teal-blue;
  @include media-breakpoint-down(lg) {
    margin: 42px 0;
    padding: 11px 0;
  }
  .social_download_links {
    display: flex;
  }
  .download-container {
    @include p-left(100px);
    @include media-breakpoint-down(lg) {
      @include p-left(50px);
    }
    h5 {
      font-size: 18px;
      line-height: 40px;
      color: $davy-grey;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 20px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      li {
        @include custom-right(25px);
        list-style: none;
        @include media-breakpoint-down(lg) {
          @include custom-right(12px);
        }
        &:last-child {
          @include custom-right(0);
        }
        a {
          font-size: 32px;
          color: $davy-grey;
          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .social-shares {
    h5 {
      font-size: 18px;
      line-height: 40px;
      color: $davy-grey;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 20px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      li {
        @include custom-right(25px);
        list-style: none;
        @include media-breakpoint-down(lg) {
          @include custom-right(12px);
        }
        &:last-child {
          @include custom-right(0);
        }
        a {
          font-size: 32px;
          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
          &:hover {
            text-decoration: none;
          }
          i.fa-facebook {
            color: #3c5a9a;
          }
          i.fa-twitter {
            color: #03a9f4;
          }
          i.fa-whatsapp {
            color: #4caf50;
          }
        }
      }
    }
  }
  
.our-impacts p a {
  color: #575756 !important;
  text-decoration: none;
}

  /** scroll to top **/
  .scroll-to-top {
    display: flex;
    .scroll-top {
      font-size: 20px;
      text-decoration: none;
    
      @include media-breakpoint-down(lg) {
        font-size: 12px;
      }
      i {
        font-size: 16px;
        @include p-right(10px);
        @include media-breakpoint-down(lg) {
          font-size: 12px;
          @include p-right(5px);
        }
      }
    }
    &:hover{
      span{
        text-decoration: underline;
      }
    }
  }
}

.our-impacts p a {
  color: #575756 !important;
  text-decoration: none;
  display: inline;
}
/** search Text box **/
.search-container {
  width: 25%;
  .search-input-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    // @media (min-width: 992px) and (max-width: 1680px) {
    //   width: 100%;
    // }
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    .search-input {
      width: 100%;
      border-radius: 44px;
      border: 0;
      padding: 0px 2rem 0px 1.5rem;
      background: $spanish-gray-op0_1;
      height: 50px;
      color: $spanish-gray;
      font-size: 1rem;
      &::placeholder {
        color: $spanish-gray;
        opacity: 1; /* Firefox */
      }
      @include media-breakpoint-down(lg) {
        padding: 0px 15px;
        font-size: 10px;
      }
      @include mobile{
        height: 35px;
      }
    }
  }
  .search-icon-btn {
    // width: 43px;
    // height: 43px;
    border: 0;
    @include right(30px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    font-size: 1.2rem;
    @include media-breakpoint-down(lg) {
      width: 30px;
      height: 30px;
      @include right(5px);
    }
    i {
      color: $mughal-Green;
      @include media-breakpoint-down(lg) {
        font-size: 15px;
      }
    }
  }
}

.pb-165 {
  padding-bottom: 165px;
  @include media-breakpoint-down(lg) {
    padding-bottom: 80px;
  }
}
.pb-128 {
  padding-bottom: 128px;
  @include media-breakpoint-down(lg) {
    padding-bottom: 64px;
  }
}

.our-impacts .section-title {
  margin-bottom: 1.5rem;
}
.our-impacts{
  .section-title{
    margin-bottom: 3rem;
  }

  .content-list{
    .button{
      font-weight: 700;
    }
  }
  .content-list:hover{

    a{
      color: $teal-blue !important;
    }
    a.btn.btn-outline{
      color: #fff !important;
      text-decoration: underline;
      background-color: #39838B;
    }
    .button{
      background-color: #39838B;
      font-weight: 700;
      color: #fff;
    }
  }

  .content-list:hover a {
    text-decoration: underline;
  }

}


.innovations .section-content {
  padding: 2rem 0;
}



.fas, .fa-solid {
  font-weight: 500;
}
.btn.btn-outline.btn-v2 {
	text-decoration: none;
}

.loader-screen{
  display: none;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
 
  align-items: center;
  justify-content: center;
  &.active{
    display: flex;
  }
  &::before {
    content: "";
    background: #fff;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    opacity: .5;
    top: 0;
    @include left(0);
  }
  img {
    max-width: 50px;
  }  
}

#search-results p {
  color: #39838b;
}
#search-results a {
  color: #39838b;
}



.popular-search-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    max-width: calc(100% / 4 - 50px);
    @include custom-right(50px);
    width: 100%;
    cursor: pointer;
    @include tablet{
      max-width: calc(100% / 3 - 50px);
    }
  }
}

.site-header .search-overlay .search-overlay-content .container ul li {
  margin-bottom: 1rem;
}


#search-results .news-card-wrapper {
  row-gap: 0%;
}



.modal-body .table-view-2-col{
  .author-img{
    display: none;
  }
}
.page-default ul li {
  list-style: disc;
  padding-bottom: 20px;
}


.user-profile-sec{
  .user-profile {
    max-width: 95%;
    margin: auto;
    @include desktop{
      max-width: 100%;
    }
  }
  .user {
    display: flex;
    flex-wrap: wrap;
    @include mobile{
      flex-direction: column;
    }
    
  }
  h3{
    color: $may-green;
    font-size: 1.8rem;
    line-height: 1.2em;
    padding-bottom:  0.2em;
    font-family: $light-font;
    @include desktop-xxl{
      font-size: 1.5rem;
    }
    @include mobile{
        font-size: 1.4rem;
    }
  }
  h6{
    color: $electric-blue;
  }
  .profile-img {
    max-width: 250px;
    width: 100%;
    height: auto;
    min-width: 150px;
    margin: 0px 5%;
    @include mobile{
      margin: 0px 0px;
      margin-bottom: 5%;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  h4{
    margin-bottom: .5rem;
  }
  h6{
    margin-bottom: 1.2rem;
  }
  .user {
    margin-bottom: 5%;
  }
  .content{
    flex: 1 1 0;
    p {
      line-height: 1.4em;
      font-size: 1.35rem;
      @include mobile{
        font-size: 1rem;
      }
    }
  }
 
}

.latest-research .section-content .content-list img {
  height: 450px;
  border-radius: 0px;
  object-fit: fill;
}

.teasetr-card {
  margin-bottom: 5%;
}

.latest-research .section-content .content-list p {
  padding-top: 1rem;
}
.latest-research .section-content {
  margin-bottom: 4rem;
}


.ct-block .image-container {
  height: 250px;
  border-radius: 10px;

}


.blogs-list .content-list .image-card {
  height: 400px;
  img{
    height: 100%;
    object-fit: cover;
  }
 }




.open-wish-summit{
  .bg-branding::before {
    bottom: unset;
    width: 450px;
    height: 450px;
    top: -24rem;
    @include tablet{
      @include right(-11rem);
      top: -14rem;
    }
  }
}

// .latest-research .section-title .title.bg-pulse .pulse-img {
//     // margin-left: -11rem;
//     // margin-right: -2rem;
//     @include custom-left( -11rem);
//     @include custom-right( -2rem);
//     margin-bottom: -4rem;
// }
.latest-research .section-content .content-list a {
  display: inline;
}

.view-all-holder {
  margin-top: rem;
}


iframe{
  max-width: 100% !important;
  width: 100% !important;
  height: 650px;
  min-height: 100%;
}


.latest-research.home-section .section-content .content-list img {
  height: auto;
}

.innovations .section-title .sub-title {
  max-width: 100%;
}

.innovations p a {
	
	text-decoration: none;
}

.innovations .content-list:hover p a {
  text-decoration: underline;
}

.paginations-section nav ul li .current {
  font-size: 24px;
}


.blog-detail-page{
  .news-card:hover{
    
    .btn.btn-outline{
      background-color: #275D2D !important;
      color: #fff;
    }
  }
}

.text-content{
  iframe{
    margin-top: 1rem;
  }
}
.wp-block-image {
  max-width: 600px;
  margin-bottom: 1rem;
  margin: 2rem 0px 2rem;
}


.latest-research  p a {
  color: #575756;
  text-decoration: none;
}


.news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Element | https://wish.a101.co/ */

div.slide-content-item button {
  margin-top: auto;
}

.news_report_section .hero-banner-wrapper img {
  min-height: 35rem;
  @include desktop-3xl{
    min-height: 30rem;
  }
  @include desktop{
    min-height: unset;
  }
}


@media only screen and (min-width:1500px ){

  .news-events-container{
    .btn-arrow-container {
      padding-top: 1rem;
    }
  
    h2, .h2 {
      font-size: 1.5rem;
    }
  }


  .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content h2, .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content .h2 {
    margin-bottom: 1rem;
  }

}

.youtube-playlist img {
  width: 100%;
}

ol li , ul li {
  font-size: 1.35rem;
  font-family: $light-font ;
  font-weight: 400;
}

.d-block.swiper-slide h5 {
  transition: all .5s ease;
}
.d-block.swiper-slide.swiper-slide-active h5 {
  opacity: 0;
}

.news-events-container .news-events-slider .slider-content-wrapper .mosueevent-on .slide-content-item .slide-content .green-bg{
  z-index: 1000;
  transition-property: z-index; 
  transition-duration: 0.3s; 
  transition-delay: 0.6s; 
  text-decoration: none;
}

.news-events-container .btn.btn-solid:hover{
  border: 2px solid #1F4E99;
  background-color: #1F4E99;
  text-decoration: underline;
  color: #fff;
}

.page-titles-wrapper ul{
  list-style: circle;
  li{
    margin-bottom: 1rem;
  }
}





.wpcf7-form select, .wpcf7-form input {
  width: 400px;
  height: 40px;
  border-radius: 0px;
  border: 1px solid #000;
  border-radius: 20px;
  padding: 0px 20px;
  display: flex;
  align-items: center;

  background-color: #fff;
}



.wpcf7-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 2%;
  br{
    display: none;
  }
  input[type="submit"] {
    min-width: 4rem;
    color: #fff;
    padding: 0px 28px;
    height: 45px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
    font-family: "DinNextLTLight", arial, serif;
    display: inline-flex;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    align-items: center;
    outline: none;
    border-radius: 100px;
    justify-content: center;
    font-size: 1rem;
    font-weight: 700;
    font-family: "DinNextLTMedium", arial, serif;
    border: 2px solid #1F4E99;
    background-color: #1F4E99;
    height: 45px;
  }
  label {
    display: flex;
    flex-direction: column;
  }
  .wpcf7-not-valid-tip {
    font-size: 0.8em;
    }
    textarea{
      width: 400px;
    }
}


.news-events-container {
  .btn.btn-outline{
    margin-top: auto;
  }
}

.slider-content-wrapper{
  .slide-content p{
    max-height: 190px;
    display: block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }
}


.content-block p{
  max-width: 1200px;
}
.content-block .size-full {
  border-radius: 10px;
}
.content-img img {
  border-radius: 10px;
  margin-bottom: 5%;
}


/* Element | https://wish.a101.co/about-qatar-foundation/# */

.content-block .content-wrapper {
  position: relative;
    
  .bg-branding::before {
    width: 20vw;
    height: 20vw;
    transform: rotate(0deg);
  }

  .bg-branding::before {
    @include right(-14vw);
    top: -7vw;
  }

}

.wpcf7-spinner {
  display: none !important;
}

/* main.css | https://wish.a101.co/wp-content/themes/wish/dist/css/main.css */

.wish-button {
  height: 51px;
  border-radius: 50px;
  padding: 0px 30px;
  background:  linear-gradient(108deg, #2281C1 0%, #2382B5 7%, #2E8E5B 62%, #339337 100%);
  text-decoration: none !important;
  font-family: "DinNextLTMedium";
  &:hover{
    background: #fff;
    color: #4FA347 !important;
    }
}
.light-mode{
  .wish-button {
    color: #575756 !important;
    background: #FFFFFF;
    &:hover{
      color: #fff !important;
      background: #1F4E99;
    }
  }
}

.page-numbers.current {
  text-decoration: underline !important;
}



/* Inline #9 | https://wish.org.qa/h-e-sheikha-hind-donates-blood-to-kick-off-doha-healthcare-week-2/ */

.wp-block-group {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -1rem;
  @include desktop-xxl{
    margin: 1rem 6rem;
  }
  @include desktop{
    margin: 1rem 1rem;
  }
  @include mobile{
      margin: 1rem -0.2rem;
  }

  .wp-block-image figcaption {
    margin-bottom: 0px;
  }


  .wp-block-image {
    display: flex;
    margin: 0px 1rem;
    flex: 1 1 0;
    @include desktop-xl{
      margin: 0px .2rem;
    }
    img {
      border-radius: 10px;
    }
  }

  .wp-block-image figure {
    height: 100%;
    min-height: 100%;
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
  }
  img {
    width: fit-content;
    object-fit: cover;
    flex: 1 1 0;
    width: 100%;
    padding: 0px !important;
  }
}
