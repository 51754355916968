#registration-page {
    margin-top: -15rem;

    .container{
        
         max-width: 93%;
        @media only screen and (min-width:1920px) {
            max-width: 1640px;
        }


    }
    .registration-banner{
        position: relative;
        overflow: hidden;
        padding-top: 10rem;
       
        .banner-image {
            position: absolute;
            @include left(0px);
            top: 0px;
            height: 100%;
            z-index: -1;
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        .banner-content {
            padding: 6% 0px 4%;
            h3{
                font-family: 'DinNextLTBold';
                color: #fff;
                font-size: 3rem;
                max-width: 450px;
                line-height: 1.2em;
                padding-bottom: 10px;
            }
        }
          
        .location {
            color: #fff;
            font-size: 2rem;
            line-height: .8em;
            display: flex;
            align-items: center;
            svg {
                width: 20px;
                height: 25px;
                @include custom-right(10px);
                margin-bottom: 5px;
            }
        }

        .buttons {
            display: flex;
            margin: 4% 0px 0px;
            list-style: none;
            padding: 0px;
            flex-wrap: wrap;
            li{
                @include custom-right(1.8rem);
            }
            .btn.btn-primary.bg-green {
                background: #4FA347;
                font-size: 1.1rem;
                height: 55px;
                padding: 0px 2rem;
                &:hover{
                    background: #5DBF54;
                    text-decoration: none !important;
                }
            }

            .btn.btn-primary.bg-white {
                color: #575756;
                font-size: 1.2rem;
                padding: 0px 2rem;
                height: 55px;
                &:hover{
                    background: #EFEFEF;
                    text-decoration: none !important;
                }
            }

        }
        
          
    }
    .form-container{
        background-color: #F4F4F4;
        border-radius: 12px;
    }


 


 
    
    .about-wish-section{
        padding: 5% 0px;
            img {
                max-width: 40%;
                float: right;
            }
            .content {
                p {
                    font-size: 1.35rem;
                    margin-bottom: 1.8em;
                    line-height: 1.5em;
                    color: #575756;
                    strong{
                        font-family: 'DinNextLTBold';
                        color: #000000;
                    }
                }
                img{
                    @include custom-left(5rem);
                    margin-bottom: 2rem;
                    border-radius: 80px 0px 80px 0px;
                }    
            }
           
    }

    .oppertunities-section{
        padding: 5% 0px;
        @include desktop-xxl{
            padding: 5% 0px 0px;
        }
        .container{
            position: relative;
            &::after {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27144%27 height=%27202%27 viewBox=%270 0 144 202%27%3E%3Cg id=%27Group_3114%27 data-name=%27Group 3114%27 transform=%27translate%28-1630 -4003%29%27%3E%3Cpath id=%27Rectangle_1385%27 data-name=%27Rectangle 1385%27 d=%27M0,0H89a20,20,0,0,1,20,20v89a0,0,0,0,1,0,0H20A20,20,0,0,1,0,89V0A0,0,0,0,1,0,0Z%27 transform=%27translate%281630 4003%29%27 fill=%27%234fa347%27/%3E%3Cpath id=%27Rectangle_1394%27 data-name=%27Rectangle 1394%27 d=%27M20,0H64a0,0,0,0,1,0,0V44A20,20,0,0,1,44,64H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z%27 transform=%27translate%281710 4141%29%27 fill=%27%230086cd%27/%3E%3C/g%3E%3C/svg%3E%0A");
                width: 100px;
                height: 200px;
                position: absolute;
                top: -7rem;
                @include right(5rem);
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        .images-zig-zag {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @include custom-left(-6rem);
            padding: 0px;
            li {
                width: 50%;
                position: relative;
                @include desktop-3xl{
                    width: 44%;
                }
                @include desktop-xxl{
                    width: 40%;
                }
                &:nth-child(1){
                    @include left(-3rem);
                }
                &:nth-child(2){
                    margin-top: -9rem;
                    @include desktop{
                        margin-top: -4rem;
                    }
                }
                &:nth-child(3){
                    width: 30%;
                    top: 2.5rem;
                    @include left(-4.5rem);
                }
                &:nth-child(4){
                    top: -6.5rem;
                    width: 25%;
                    @include right(1.5rem);
                    @include desktop{
                        top: -1.5rem;
                    }
                }
            }
        }

        .count-container{
            display: flex;
            justify-content: space-between;
            height: 100%;
            padding-bottom: 4.5rem;
            
            .count-list{
                height: 100%;
                .number{
                    background: linear-gradient(95deg, #2281c1 0%, #2382b5 1%, #2e8e5b 16%, #339337 100%) 0% 0% no-repeat padding-box;
                    background-clip: padding-box;
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                    font-size: 4rem;
                    font-family: 'DinNextLTBold';
                    @include  desktop-xxl{
                        font-size: 3rem;
                    }
                }
            }
            ul{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0px;
                li{
                    margin-bottom: 2.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    &:nth-child(1){
                        min-height: 7.8rem;
                    }
                }
            }
           
            h5{
                font-size: 1.6rem;
                font-family: "DinNextLTMedium";
                color: #575756;
                @include desktop-xxl{
                    font-size: 1.2rem;
                }
            }
        }
    }


    .our-partner-section{
        padding: 5% 0px 0px;
        .partner-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;  
            align-items: center;
            margin: 0px -1.5rem;   
            padding: 0px;
            @include desktop{
                margin: 0px -1rem;   
            }
            li{
                max-width: calc(100% / 6 - 3rem);
                margin: 1rem 1.5rem 3rem;
                width: fit-content;
                @include desktop{
                    max-width: calc(100% / 4 - 2rem);
                    margin: 1rem 1rem 2rem;
                }
                img{
                    height: 85px;
                    width: auto;
                    object-fit: contain;
                }
            }
        }
  
    }

    .wish-highlight-player-section{
        padding: 5% 0px;
        .video-container {
            max-width: 90%;
            margin: 1rem auto 0px;
            position: relative;
        }

        .playback-control{
            border: none;
            background: none;
            position: absolute;
            bottom: 2.5rem;
            @include left(2.5rem);
            color: #fff;
            font-size: 1rem;
            z-index: 10;
            span{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            &.play{
                #pause{
                    display: none;
                }
            }
            svg{
                margin-bottom: 6px;
            }
            &.pause{
                #play{
                    display: none;
                }
            }
        }
        video {
            height: auto;
            width: 100%;
            @include custom-left(auto);
            }
    }


    .discover-doha-seciton {
        padding: 0px 0px 4%;
        .discover-doha-banner {
            width: 100vw;
            height: auto;
            margin-bottom: 5%;
        }
        .selected-disover-list{
            padding: 0px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 1.5rem;
            grid-row-gap: 1rem; 
            h4 {
                font-size: 1.5rem;
                font-family: "DinNextLTBold";
                margin-bottom: .4em;
                color: #000000;
                a{
                    color: #000000;
                }
            }
            p{
                color: #575756;
                font-size: 1.4rem;
            }
        }
        figure {
            margin-bottom: 2rem;
        }
        .btn.bg-blue{
            margin-top: 3rem;
            background-color:  #1F4E99;
            svg{
                @include custom-left(10px);
            }
        }


          
    }

}


.registraction-section{
    padding: 4% 0px;
    @include desktop{
        padding: 8% 0px;
    }
    .section-title {
        padding-bottom: 0px;
    }
    .row{
        @include custom-left(calc(-1 * var(--bs-gutter-x) * 0.5));
        @include custom-right(calc(-1 * var(--bs-gutter-x) * 0.5));
    }
    .image {
        margin: auto 0px 0px;
        border-radius: 90px 0px 90px 0px;
        overflow: hidden;
        width: 100%;

      }
    .content p {
        font-size: 1.35rem;
        max-width: 600px;
    }
    
    .form-container {
        background-color: #F4F4F4;
        padding: 3.5rem 5rem 3.5rem;
        border-radius: 10px;
        position: relative;
        @include desktop{
            padding: 2.5rem 3rem 3.5rem;
        }
        p {
            margin-bottom: 1.5em;
        }
        input::placeholder {
            color: #9C9C9B;
        }
        
        .thankyou-page {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .thankyou-message {
            display: none;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0px;
            align-items: center;
            justify-content: center;
            left: 0px;
            &.active{
                display: flex;
            }
        }
        
    }
    label {
        font-family: 'DinNextLTBold';
    }
      
    .wpcf7-form select, .wpcf7-form input {
        width: 100%;
        border: none;
        border-radius: 0px;
        padding: 0px;
        background-color: transparent;
        border-bottom: .5px solid #9C9C9B;
        font-size: 1.2rem;
        font-family: "DinNextLTLight";
    }
    .wpcf7-form-control-wrap {
        margin-top: 10px;
    
    }
    .wpcf7-form {
        position: relative;
    }

    .wpcf7-response-output {
        display: none;
    }
    .wpcf7-form-control-wrap {
        position: relative;
    }
    
    .wpcf7-not-valid-tip {
        position: absolute;
        top: .5em;
        @include right(0px);
    }


    .register-button {
        display: flex;
        max-width: fit-content;
        align-items: center;
        background: #4FA347;
        border-radius: 30px;
        text-decoration: none !important;
        padding: 0px 30px;
        font-family: 'DinNextLTMedium';
        margin-top: 4%;
        svg{
            @include custom-left(10px);
        }
    }


    #submit {
        text-decoration: none !important;
        border: none !important;
        font-family: 'DinNextLTMedium';
    }

}

.why-attend{
    padding: 4% 0px;
    border-radius: 0px 90px 0px 0px;
    &.bg-blue{
        background: #1F4E99;
        *{
            color: #fff;
        }
    }
    .container{
        position: relative;
        &::after{
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='144' height='202' viewBox='0 0 144 202'%3E%3Cg id='Group_3114' data-name='Group 3114' transform='translate(-1630 -4003)'%3E%3Cpath id='Rectangle_1385' data-name='Rectangle 1385' d='M0,0H89a20,20,0,0,1,20,20v89a0,0,0,0,1,0,0H20A20,20,0,0,1,0,89V0A0,0,0,0,1,0,0Z' transform='translate(1630 4003)' fill='%234fa347'/%3E%3Cpath id='Rectangle_1394' data-name='Rectangle 1394' d='M20,0H64a0,0,0,0,1,0,0V44A20,20,0,0,1,44,64H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z' transform='translate(1710 4141)' fill='%230086cd'/%3E%3C/g%3E%3C/svg%3E%0A");
            width: 100px;
            height: 200px;
            position: absolute;
            top: -7rem;
            @include right(.5rem);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    h3{
        font-size: 2.8rem;
        font-family: 'DinNextLTBold';

    }
    p{
        font-size: 1.35rem;
        line-height: 1.4em;
        color: #575756;
        font-family: "DinNextLTLight";
      
        strong{
            font-family: "DinNextLTBold";
        }
    }
    .content  {
        max-width: 95%;
       
        margin-bottom: 3%;
    }
    .topics {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px; 
        @include p-left(0px);

        h5{
            font-size: 1.8rem;
            max-width: fit-content;
            max-width: 80%;
            
        }
        .icon-container {
            border-bottom: 1px dashed #fff;
            margin: 3rem 0px;
            padding: 2rem 0px;
          }
          
        img {
            max-height: 100px;
            object-fit: fill;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: auto;
        }
    }
}   

@include desktop-xxl{
    #registration-page .registration-banner .banner-content h3, #registration-page .registration-banner .banner-content .h3 {
        font-size: 52px;
    }
    .why-attend .topics h5, .why-attend .topics .h5 {
        font-size: 1.8rem;
    }
}
@include desktop{
    #registration-page .registration-banner .banner-content h3, #registration-page .registration-banner .banner-content .h3 {
        font-size: 1.8rem;
    }
    #registration-page .registration-banner .location {
        font-size: 1.5rem;
    }
    #registration-page .registration-banner .location svg {
        width: 15px;
    }
    #registration-page .registration-banner .banner-content h3, #registration-page .registration-banner .banner-content .h3 {
        max-width: 250px;
    }
}

@include desktop-xl{
    .registraction-section {
        padding: 6% 0px;
    }
    #registration-page .oppertunities-section .container::after {
        @include right(2rem);
    }
}

@include desktop{

    .why-attend .topics h5, .why-attend .topics .h5 {
        font-size: 1.4rem;
    }
    
     .why-attend .topics .icon-container {
        margin: 1rem 0px;
    }
    
  .why-attend p {
        font-size: 1.2rem;
    }
    
     .registraction-section .content p {
        font-size: 1.2rem;
    }
    

    #registration-page .oppertunities-section .count-container .count-list .number {
      /* font-size: 3rem; */
      font-size: 2rem;
    }
  
    #registration-page .oppertunities-section .count-container h5, #registration-page .oppertunities-section .count-container .h5 {
      font-size: 1rem;
    }
  
}
@include tablet{
    .registraction-section .image {
        margin: 5% 0px 0px;
        border-radius: 40px 0px 40px 0px;
    }
    #registration-page .oppertunities-section .container::after {
        width: 80px;
        height: 180px;
        top: -4rem;
    }
    .why-attend .topics img {
        max-height: 60px;
    }
    .why-attend .topics h5, .why-attend .topics .h5 {
        font-size: 1.2rem;
    }
    #registration-page .about-wish-section .content p {
        font-size: 1.2rem;
    }
    #registration-page .about-wish-section .content img {
        @include custom-left(3rem);
    }
    #registration-page .oppertunities-section .count-container .count-list {
        height: 100%;
        min-width: 120px;
    }
    #registration-page .oppertunities-section .count-container {
        @include custom-left(-50px);
    }
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(1) {
        @include left(-1rem);
    }
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(2) {
        margin-top: -2rem;
    }
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(3) {
        top: 1rem;
        @include left(-2.5rem);
    }
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(4) {
        top: -1.5rem;
    }
    #registration-page .discover-doha-seciton .selected-disover-list p {
        font-size: 1.2rem;
        line-height: 1.2em;
    }
    #registration-page .discover-doha-seciton .selected-disover-list h4, #registration-page .discover-doha-seciton .selected-disover-list .h4 {
        font-size: 1.2rem;
    }
}

@include mobile{
    #registration-page .registration-banner .location {
        font-size: 1rem;
    }
    #registration-page .registration-banner .location svg {
        width: 12px;
    }
    #registration-page .oppertunities-section .container::after {
        display: none;
    }
    #registration-page .registration-banner .banner-content h3, #registration-page .registration-banner .banner-content .h3 {
        font-size: 1.2rem;
        max-width: 150px;
    }
  
    #registration-page .registration-banner .banner-content {
        padding: 14% 0px 10%;
    }
    
    #registration-page .registration-banner .buttons .btn.btn-primary.bg-green {
        height: 45px;
        font-size: 1rem;
    }
  
    .registraction-section {
        padding: 4rem 0px;
    }
    
    #registration-page .container {
        max-width: 90%;
    }
    
    .why-attend .container::after {
        width: 80px;
        height: 90px;
        top: -6rem;
    }
    
     .why-attend {
        padding: 4rem 0px;
    }
    
     .why-attend .topics {
        grid-template-columns: repeat(1, 1fr);
    }
  
    #registration-page .about-wish-section {
        padding: 4rem 0px 0px;
    }
    
    #registration-page .about-wish-section .content img {
        @include custom-left(0px);
        margin-top: 1rem;
    }
    
    #registration-page .about-wish-section img {
        max-width: 100%;
        width: 100%;
    }
    
    #registration-page .oppertunities-section .images-zig-zag {
        justify-content: space-between;
        @include custom-left(0px);
        align-items: flex-start;
    }
    
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(1) {
        @include left(0px);
    }
    
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(2) {
        margin-top: 0px;
    }
  
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(3) {
        width: 45%;
        @include left(0px);
    }

    #registration-page .oppertunities-section .images-zig-zag li:nth-child(4) {
    top: 0px;
    }

    #registration-page .oppertunities-section .images-zig-zag li:nth-child(4) {
        width: 45%;
        @include right(0);
    }
    
    #registration-page .oppertunities-section .images-zig-zag li {
      width: 45%;
      margin: .5rem 0px;
    }
    #registration-page .oppertunities-section {
        padding: 0px 0px 0px;
    }
  
    .about-us-page ol, ul {
      @include p-left(0px);
    }
  
    #registration-page .about-wish-section .content p {
        font-size: 1.2rem;
     }
  
    #registration-page .discover-doha-seciton .selected-disover-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .registraction-section .form-container {
        padding: 1.5rem 2rem 2.5rem;
    }
    #registration-page .registration-banner .buttons .btn.btn-primary.bg-white {
        padding: 0px 1.5rem;
        height: 45px;
        font-size: 1rem;
    }
    #registration-page  .section-title .title {
        font-size: 1.5rem;
    }
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(3) {
        top: 0px;
    }
    #registration-page .oppertunities-section .images-zig-zag li:nth-child(2) {
        margin-top: 0.5rem;
    }
    #registration-page .oppertunities-section .count-container ul li:nth-child(1) {
        min-height: 5.8rem;
    }
    #registration-page .our-partner-section .partner-list li {
        max-width: calc(50% - 2rem);
    }

    .why-attend h3,  .why-attend .h3 {
        font-size: 1.8rem;
    }
    #registration-page .discover-doha-seciton {
        padding: 0px 0px 4rem;
    }
    #registration-page .discover-doha-seciton .btn.bg-blue {
        margin-top: 0rem;
    }
    #registration-page .discover-doha-seciton .selected-disover-list p {
        font-size: 1rem;
    }
    #registration-page .discover-doha-seciton .selected-disover-list h4, #registration-page .discover-doha-seciton .selected-disover-list .h4 {
        font-size: 1rem;
    }
    #registration-page .discover-doha-seciton figure {
        margin-bottom: 1rem;
    }
    #registration-page .discover-doha-seciton .selected-disover-list {
        grid-column-gap: 1rem;
    }
    #registration-page .registration-banner .buttons li {
        @include custom-right(10px);
    }
    #registration-page .wish-highlight-player-section .playback-control {
        bottom: 1.5rem;
        @include left(1.5rem);
    }
    #registration-page .discover-doha-seciton .discover-doha-banner {
        margin-bottom: 3rem;
    }
 
    
}




.wpcf7-form input[type="submit"] {
    height: 38px;
  }
  
  .registraction-section .wpcf7-form select, .registraction-section .wpcf7-form input {
    font-size: 1rem;
  }
  
  .partner-list{
    a{
        justify-content: center;
    }
  }