.innovations {
  background-color: $spanish-gray-op0_1;
  padding-top: 125px;
  padding-bottom: 83px;
  @include media-breakpoint-down(lg) {
    padding-top: 62px;
    padding-bottom: 83px;
  }
  .section-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    margin: 0 auto;
    padding: 2rem 0 3%;
    
 
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
      grid-gap: 7px;
    }
    @include desktop-xl{
      margin: 0 auto 2rem;
    }
    & .content-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: fit-content;
      @include media-breakpoint-down(lg) {
        width: 181px;
      }
      @include mobile{
        width: 100%;
      }
      .image-card {
        overflow: hidden;
        border-radius: 10px;
        flex: 1;
        *{
          height: 100%;
          object-fit: cover;
        }
        &:hover img {
          -webkit-transform: scale(1.08);
          transform: scale(1.08);
        }
        img {
          -webkit-transition: 0.4s ease;
          transition: 0.4s ease;
          height: 250px;
          border-radius: 10px;
        }
        &:hover + p {
          text-decoration: underline;
          color: $teal-blue;
        }
      }

      p {
        padding-top: 5%;
        a{
          font-family: $light-font;
        }

        @include media-breakpoint-down(lg) {
          padding-top: 20px;
        }
        @include desktop-xl{
          font-size: 1.2rem;
          line-height: 1.5em;
          padding-top: 31px;
        }

        i {
          color: $may-green;
          font-size: 17px;
          @include media-breakpoint-down(lg) {
            font-size: 10px;
          }
        }
      }
    }
  }
}


.innovations {
  padding-top: 5%;
  padding-bottom: 5%;
  .sub-title{
    margin-bottom: 0px;
  }
}