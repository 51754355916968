.latest-research {
  &.home-section {
    padding-bottom: 5%;
    padding-top: 5%;
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 30px;
  }
  .section-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 65px;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }

    & .content-list {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      &:hover img {
        -webkit-transform: scale(1.03);
        transform: scale(1.03);
      }
      p {
        color: $davy-grey;
      }
      &:hover {
        p {
          text-decoration: underline;
          color: $may-green;
        }
      }
      a {
        &:hover {
          color: $may-green;
          text-decoration: underline !important;
        }
      }
      img {
        box-shadow: 0px 3px 6px #00000052;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
      }
      p {
        padding-top: 41px;
        @include desktop-xl{
          padding-top: 25px;
        }
        @include media-breakpoint-down(lg) {
          padding-top: 20px;
        }
        i {
          color: $may-green;
          font-size: 17px;
          @include media-breakpoint-down(lg) {
            font-size: 10px;
          }
        }

      }
      &:hover{
        a {
          color: $may-green;
        }
        
      }
    }
  }
}
