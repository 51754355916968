.back-button-container {
  .btn-back {
    background: $teal-blue;
    color: #fff;
  }
  margin-bottom: 78px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 39px;
  }
}
.teasetr-card {
  margin-bottom: 5%;
  padding: 80px 84px;
  background-color: $teal-blue;
  border-radius: 12px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 40px;
    padding: 40px 42px;
  }
  .images-card {
    img {
      box-shadow: 0px 3px 6px #00000052;
    }
  }
  .text-content {
    padding: 0 54px 0 150px;
    height: 100%;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
      padding: 20px 0 0 0;
    }
    .year-text {
      font-size: 21px;
      line-height: 13px;
      color: #fff;
      font-family: $bold-font;
      margin-bottom: 20px;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    h2 {
      font-size: 2rem;
      line-height: 45px;
      color: $white;
      font-family: $bold-font;
      margin-bottom: 30px;
      @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }
    p {
      color: $white;
    }
  }
}
.text-wrapper {
  h5 {
    font-size: 22px;
    color: $may-green;
    font-family: $light-font;
    line-height: 36px;
    @include media-breakpoint-down(lg) {
      font-size: 12px;
      line-height: 13px;
    }
  }
  ul {
    li {
      list-style: disc;
    }
  }
}
