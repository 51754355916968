
.wish-button {
    height: 45px;
    padding: 0px 25px;
    font-size: 1rem;
    

}

a.buttons-v2{
    padding-top: 2px;
}
.buttons-v2{
    max-width: fit-content;
    cursor: pointer;
    padding: 0px 28px;
    min-width: 4rem;
    height: 2.5rem;
    display: inline-flex;
    justify-content: center;
    outline: none;
    border-radius: 100px;
	align-items: center;
    transition: all 0.3s ease;
    font-size: 1rem;
	font-weight: 700;
	font-family: "DinNextLTMedium", arial, serif;
    text-decoration: none !important;
    position: relative;
    @include mobile-sm{
        font-size: .8rem;
    }
    &.green-bg{
        height: 2.35rem;
        font-size: .85rem;
        background-color: #4FA347;
        border-color: #4FA347;
        color: #fff;
        &:hover{
            background-color: #5DBF54;
            border-color: #5DBF54;
        }
    }
    &.white-bg{
        height: 2.35rem;
        font-size: .85rem;
        background-color: #FFFFFF;
        border-color: #FFFFFF;
        color: #575756 !important;
        &:hover{
            background-color: #EFEFEF;
            border-color: #EFEFEF;
        }
    }
    &.blue-bg{
        background-color: #1F4E99;
        border-color: #1F4E99;
        color: #fff;
        height: 3rem;
        &:hover{
            background-color: #1F4EBE;
            border-color: #1F4EBE;
        }
    }
    &.wish-button{
        border-radius: 50px;
        background: linear-gradient(108deg, #2281C1 0%, #2382B5 7%, #2E8E5B 62%, #339337 100%);
        text-decoration: none !important;
        font-family: "DinNextLTMedium";
    }
    &.arrow-right{
        &::after{
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 5.00001C0 4.8674 0.0526784 4.74022 0.146447 4.64645C0.240215 4.55268 0.367392 4.50001 0.5 4.50001H12.293L9.146 1.35401C9.05211 1.26012 8.99937 1.13278 8.99937 1.00001C8.99937 0.86723 9.05211 0.739893 9.146 0.646006C9.23989 0.552119 9.36722 0.499374 9.5 0.499374C9.63278 0.499374 9.76011 0.552119 9.854 0.646006L13.854 4.64601C13.9006 4.69245 13.9375 4.74763 13.9627 4.80837C13.9879 4.86912 14.0009 4.93424 14.0009 5.00001C14.0009 5.06577 13.9879 5.13089 13.9627 5.19164C13.9375 5.25238 13.9006 5.30756 13.854 5.35401L9.854 9.35401C9.76011 9.44789 9.63278 9.50064 9.5 9.50064C9.36722 9.50064 9.23989 9.44789 9.146 9.35401C9.05211 9.26012 8.99937 9.13278 8.99937 9.00001C8.99937 8.86723 9.05211 8.73989 9.146 8.64601L12.293 5.50001H0.5C0.367392 5.50001 0.240215 5.44733 0.146447 5.35356C0.0526784 5.25979 0 5.13261 0 5.00001Z' fill='white'/%3E%3C/svg%3E%0A");
            width: 15px;
            height: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            @include custom-left(10px);
        }
    }
   
    &.arrow-left{

        &::before{
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.0009 5.00001C14.0009 5.13262 13.9482 5.25979 13.8544 5.35356C13.7606 5.44733 13.6335 5.50001 13.5009 5.50001L1.70785 5.50001L4.85485 8.64601C4.94874 8.7399 5.00149 8.86723 5.00149 9.00001C5.00149 9.13279 4.94874 9.26012 4.85485 9.35401C4.76097 9.4479 4.63363 9.50064 4.50085 9.50064C4.36808 9.50064 4.24074 9.4479 4.14685 9.35401L0.146854 5.35401C0.100291 5.30756 0.0633487 5.25239 0.0381422 5.19164C0.0129358 5.1309 -3.91006e-05 5.06578 -3.91006e-05 5.00001C-3.91006e-05 4.93424 0.0129358 4.86912 0.0381422 4.80838C0.0633487 4.74763 0.100291 4.69246 0.146854 4.64601L4.14685 0.646009C4.24074 0.552123 4.36808 0.499378 4.50085 0.499378C4.63363 0.499378 4.76097 0.552123 4.85485 0.646009C4.94874 0.739896 5.00149 0.867234 5.00149 1.00001C5.00149 1.13279 4.94874 1.26012 4.85485 1.35401L1.70785 4.50001L13.5009 4.50001C13.6335 4.50001 13.7606 4.55269 13.8544 4.64646C13.9482 4.74022 14.0009 4.8674 14.0009 5.00001Z' fill='white'/%3E%3C/svg%3E%0A");
            width: 15px;
            height: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            @include custom-right(10px);
        }
    }
    &.light-bg{
        color: #575756;
        font-family: "DinNextLTMedium";
        background-color: #FFFFFF;
        font-size: 1.1rem;
        &:hover{
            background-color: #EFEFEF;
        }
    }

}


@include mobile{
    .buttons-v2 {
        padding: 0px 20px;
        height: 2rem;
    }
    
    .buttons-v2 {
      font-size: 0.6rem;
    }
  
    .buttons-v2.arrow-left::before {
        width: 10px;
        height: 5px;
    }
  
    .breif-wrapper  .row > * {
        @include p-right(0px);
        @include p-left(0px);
    }
    .buttons-v2.green-bg {
        height: 1.8rem;
    }.buttons-v2
    .buttons-v2{
        padding-top: 1px;
    }
    .buttons-v2.blue-bg{
        height: 2.5rem;
        font-size: 0.9rem;
    }
    .buttons-v2.green-bg {
        font-size: 0.8rem;
    }
}

a.buttons-v2[target="_blank"] , .summit-list a[target="_blank"] {
    position: relative;
    &::after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-box-arrow-up-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z'/%3E%3C/svg%3E");
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;
        top: 5px;
        right: 10px;
        background-position: center;
        background-size: contain;
        filter: invert(1);
        padding: 2px;
    }
    &:hover{
        &::after{
            filter: unset;
        }
  
    }
}
video::-webkit-media-controls-panel {
    display: flex !important;
    opacity: 1 !important;
 }
 .video-container::after {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0px;
    bottom: 10px;
    z-index: 10;
}
.video-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: auto;
    left: 0px;
    bottom: 50px;
    top: 0px;
    z-index: 10;
}
 
.buttons-v2[target="_blank"] {
    &::after{
        width: 12px;
        height: 12px;
    }
}
a.buttons-v2[target="_blank"]::after {
    width: 12px;
    height: 12px;
}

@include mobile{
    .buttons-v2.arrow-left::before {
        top: -2px;
        position: relative;
      }
      .buttons-v2.arrow-left::before {
        width: 11px;
        height: 8px;
      }
       .buttons-v2.arrow-left::before {
        @include custom-right(7px);
      }

    .buttons-v2.white-bg {
        height: 1.8rem;
    }
    .innovation-competition-page .apply-banner-block .content {
        margin: 0px 1.5rem;
    }
    #registration-page .row{
        margin: 0px 0px !important;
    }
    #registration-page .wish-highlight-player-section .playback-control {
        @include left(.5rem);
        bottom: 1.5rem;
    }
    button.playback-control.pause > span {
        font-size: .8rem;
    }
    #registration-page .oppertunities-section .count-container {
        padding-bottom: 2rem;
    }
    #registration-page .row > * {
        padding-right: 0px;
        padding-left: 0px;
    }
    .footer  .row {
        margin: auto 0px;
    }
    .footer .row > .col-lg-6 {
        padding:0px 1rem;
    }
    .arrow-container {
        display: flex;
        align-items: center;
    }
}



@include mobile{
    a.buttons-v2[target="_blank"]::after, .summit-list a[target="_blank"]::after {
        right: 5px;
    }
    a.buttons-v2[target="_blank"]::after {
        width: 8px;
        height: 8px;
    }
    .banner-img img {
        min-width: 100%;
    }
    .innovation-competition-page .apply-banner-block .content {
        @include custom-right(0px);
    }
}
html[dir="rtl"]{
    .buttons-v2.arrow-right::after {
        transform: scaleX(-1);
    }
    .buttons-v2.arrow-left::before {
        transform: scaleX(-1);
    }
  
}
