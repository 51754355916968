.blogs-list {
  .section-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }
  .content-list {
    .image-card {
      overflow: hidden;
      transition: 0.4s ease;
      border-radius: 10px;
    }
    &:hover img {
      -webkit-transform: scale(1.03);
      transform: scale(1.03);
    }
    img {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
    }
    .project-title-date {
      display: flex;
      justify-content: space-between;
      padding: 1.2rem 0 1rem 0;
      color: $spanish-gray;
      align-items: flex-start;

      .project-title {
        font-family: "DinProBold";
        font-size: 18px;
        line-height: 1.5em;
      }
      .project-date {
        line-height: 2em;
        width: 200px;
        font-size: 18px;
        line-height: 22px;
        text-align: right;
      }
    }
    p {
      line-height: 1.2em;
      font-size: 1.5rem;
      font-family: "DinNextLTRegular";
      margin-bottom: 1.5rem;
    }
    .btn {
      border-color: $mughal-Green;
      color: $mughal-Green;
      &:hover {
        background-color: $mughal-Green;
        color: $white;
      }
    }
  }
  .content-list:hover {
    p{
      text-decoration: underline;
      color: #275D2D;
    }
    .btn {
      background-color: #275d2d;
      color: #fff;
    }
 
  }
}

.blog-detail-page {
  .box-content-wrapper {
    .date-time {
      line-height: 1.2em;
      font-size: 1rem;
      font-family: "DinNextLTRegular";
      // padding-bottom: 91px;
    }
  }
  .content-wrapper {
    .container {
      max-width: 1364px;
      legend {
        line-height: 36px;
        font-size: 22px;
        font-family: $regular-font;
        color: $spanish-gray;
      }
    }
  }
  .similar-news {
    .news-card {
      &:hover {
        .description {
          color: $mughal-Green;
        }
      }
    }
  }
}

.description {
  line-height: 1.2em;
}