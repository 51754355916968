/* Primary Colors*/
$cobalt-Blue: #1f4e99;
$celtic-blue: #275dcd;
$mughal-Green: #275d2d;
$may-green: #4fa347;
$spanish-gray: #707070;
$davy-grey: #575756;

/* Secondary Colors*/
$urobilin-yellow: #ddb526;
$asparagus-green: #7d9c6a;
$ruddy-brown: #c25131;
$dark-pastel-blue: #1F4E99;
$teal-blue: #39838b;
$saddle-brown: #944e10;
$violet: #923a7f;
$dark-silver: #707070;
$dogwood-rose: #d41367;
$antique-ruby: #8e1537;
$Gold: #ffb600;
$ling-blue: #acd9ff;
$electric-blue: #0086cd;
$celadon-green: #28878b;

/* other colors */
$white: #fff;
$black: #000;

$white-op0_34: rgba(255, 255, 255, 0.34);
$spanish-gray-op0_1: rgba(156, 156, 155, 0.1);
$spanish-gray-op0_4: rgba(156, 156, 155, 0.4);
$dark-pastel-blue-op0_34: rgba(128, 162, 205, 0.34);
$linear-gradient: transparent linear-gradient(119deg, #2281c1 0%, #2382b5 7%, #2e8e5b 71%, #339337 100%) 0% 0% no-repeat padding-box;
