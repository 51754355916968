.news-events-container {
  margin-bottom: 130px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 60px;
  }
  .news-events-slider {
    display: none;
    // padding-left: 130px;

    // @media (min-width: 2560px) {
    //   padding-left: 440px;
    // }
    &.desktop {
      display: block;
      padding-bottom: 40px;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    &.mobile {
      @include custom-left(0px);
      @include custom-right(0px);
      @include media-breakpoint-down(lg) {
        display: block;
        @include p-left(0px);
      }
    }

    .arrow-container {
      padding: 40px 0;
      background-color: $white;
      position: relative;
      z-index: 3;
      @include mobile{
        display: flex;
        align-items: center;
        
      }
    }

    .swiper-slide {
      width: 480px;
      h5 {
        font-size: 24px;
        line-height: 36px;
        margin-top: 30px;
      }
      img {
        filter: brightness(70%);
      }
    }
    .swiper-slide-active {
      width: 900px;
      // @media (max-width: 1680px) {
      //   margin-right: 400px !important;
      // }
      // @include media-breakpoint-down(lg) {
      //   margin-right: 0 !important;
      // }
      .slide-inner {
        .media-wrapper {
          border-top-right-radius: 0;
        }
        img {
          filter: brightness(100%);
        }
        &:hover img {
          -webkit-transform: none;
          transform: none;
        }
      }
    }
    .slide-inner {
      display: block;
      width: 40%;
      .media-wrapper {
        // width: 480px;
        width: 100%;
        display: block;
        overflow: hidden;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 500ms;
        transition-duration: 800ms;
        border-top-right-radius: 100px;
        border-bottom-left-radius: 100px;
        @include media-breakpoint-down(lg) {
          border-top-right-radius: 0;
        }
      }
      &:hover {
        h5 {
          text-decoration: underline;
          color: $may-green;
        }
      }
      &:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 400px;

        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        filter: brightness(70%);
        @include desktop-xxl {
          width: 100%;
        }
      }
    }
    .slider-content-wrapper {
      position: absolute;
      top: 0;

      background: #f0f0f0;
      border-top-right-radius: 100px;
      border-bottom-left-radius: 100px;
      height: 400px;
      width: 55%;
      @include desktop-3xl{
        width: 60%;
      }
      @include desktop{
        width: 100%;
      }
      // @media (max-width: 1680px) {
      //   width: 800px;
      // }
  
      .slide-content-list {
        height: 100%;
      }

      .slide-content-item {
        display: flex;
        justify-content: end;
        align-items: flex-start;
        height: 100%;

        .slide-content {
          width: 435px;
          padding: 3rem 30px;
          &:hover {
            button {
              &:hover {
                background-color: $teal-blue;
                color: $white;
                text-decoration: none;
                border-color: $teal-blue;
              }
            }
          }
          @media (max-width: 1680px) {
            width: 53%;
          }
          .eyrbrow-text-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .project-title {
              font-size: 1.2rem;
              line-height: 40px;
              font-family: $bold-font;
              color: $may-green;
            }
            .project-date {
              font-size: 18px;
              line-height: 22px;
              color: $spanish-gray;
            }
          }
          h2 {
            margin-bottom: 40px;
          }
          p {
            line-height: 1.2em;
            font-size: 1.1rem;
            // line-height: 32px;
          }
          .btn {
            position: relative;
            z-index: 0;
          }
        }
      }
    }

    &.mobile {
      @include custom-left(30px);
      @include custom-right(30px);
      margin-bottom: 30px;
      .slide-inner {
        background: #f0f0f0;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        width: 100%;
        overflow: hidden;
        .media-wrapper {
          @include media-breakpoint-down(lg) {
            width: 35%;
            height: 258px;
            float: left;
          }
        }

        img {
          border-bottom-left-radius: 30px;
          @media (min-width: 992px) and (max-width: 1680px) {
          }
          @include media-breakpoint-down(lg) {
            width: 100%;
            height: 258px;
          }
        }

        .slide-content {
          padding: 20px;
          width: 65%;
          float: left;
          .eyrbrow-text-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .project-title {
              font-size: 18px;
              line-height: 40px;
              font-family: $bold-font;
              color: #4FA347;
              @include media-breakpoint-down(lg) {
                font-size: 9px;
                line-height: 13px;
                margin-bottom: 10px;
              }
            }
            .project-date {
              font-size: 18px;
              line-height: 22px;
              color: $spanish-gray;
              @include media-breakpoint-down(lg) {
                font-size: 9px;
                line-height: 11px;
              }
            }
          }
          h2 {
            margin-bottom: 40px;
            @include media-breakpoint-down(lg) {
              margin-bottom: 20px;
              font-size: 16px;
              line-height: 19px;
            }
          }
          p {
            line-height: 32px;
            @include media-breakpoint-down(lg) {
              line-height: 18px;
            }
          }

          .btn {
            position: relative;
            z-index: 3;
          }
        }
      }
    }
  }
  .btn-arrow-container {
    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: space-between;
    }
    .cta__slider-arrows {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }

  .cta__slider-arrows {
    display: flex;
    justify-content: end;

    .wrap {
      display: flex;
      .swiper-btn-prev {
        @include custom-right(30px);
        @include media-breakpoint-down(lg) {
          @include custom-right(10px);

        }
      }
      .swiper-btn-next,
      .swiper-btn-prev {
        width: 63px;
        height: 63px;
        border-radius: 100%;
        background-color: $dark-pastel-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(lg) {
          width: 32px;
          height: 32px;
        }
        &:hover{
          background-color: #1F4EBE;
        }
        i {
          color: $white;
          font-size: 30px;
          @include media-breakpoint-down(lg) {
            font-size: 12px;
          }
        }
      }
    }
  }
}




.news-events-container .news-events-slider .slider-content-wrapper {
  width: 55%;
}
.wp-block-image.size-large {
  padding: 5% 2.5%;
  @include mobile{
    padding: 2%;
  }
}

.d-block.swiper-slide.swiper-slide-next {
  // margin-right: -16rem;
  @include custom-right(-19rem);
}
// @include  desktop-xxl{
//   .d-block.swiper-slide.swiper-slide-next {
//     // margin-right: -16rem;
//     @include custom-right(-17rem);
//   }
.content-wrapper ul {
  list-style: circle;
}

.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image) {
  width: 100%;
}
.wp-block-gallery .wp-block-image img {
  margin: 0px !important;
}
.content-wrapper li {
  margin-bottom: 1rem;
}
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  @include mobile{
    flex-direction: column;
  }
  img{
    object-fit: cover;
  }
}
// }
.d-block.swiper-slide.swiper-slide-active {
  // margin-right: 4rem;
  @include custom-right(4rem);
}

.sticky-sie-bar ul {
  list-style: none;
}


