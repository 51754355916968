.hero-banner-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  margin: 0 0 100px 0;

  @include media-breakpoint-down(lg) {
    margin: 0 0 20px 0;
  }
  &::after {
    content: "";
    background-color: $asparagus-green;
    border: 200px;
    width: 1100px;
    height: 1100px;
    position: absolute;
    @include right(-110px);
    top: -248px;
    border-radius: 100%;
    z-index: 0;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    background: url("/assets/images/globe_white.svg");
    width: 500px;
    height: 500px;
    @include right(-200px);
    background-repeat: no-repeat;
    bottom: -250px;
    opacity: 0.16;
    z-index: 10;
  }
  .flex-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    @include media-breakpoint-down(lg) {
      background-color: $asparagus-green;
    }
    .banner-content {
      @include p-right(100px);
      @include media-breakpoint-down(lg) {
        padding: 50px;
      }
      .eyebrowed-text {
        font-size: 21px;
        line-height: 13px;
        color: $white-op0_34;
        font-family: $bold-font;
        margin-bottom: 20px;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
      h2 {
        font-size: 3rem;
        font-family: $medium-font;
        line-height: 72px;
        color: $white;
        @include desktop-xxl{
          font-size: 52px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 30px;
          line-height: 46px;
        }
      }
      h5 {
        color: $white;
        font-size: 22px;
        font-family: $light-font;
        padding-bottom: 20px;
        padding-top: 50px;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
          padding-bottom: 10px;
          padding-top: 25px;
        }
        strong{
          font-family: $bold-font;
        }
      }
      p {
        color: $white;
      }
      .location {
        color: $white;
        font-size: 20px;
        font-family: $light-font;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
        i {
          @include p-right(10px);
        }
      }
    }
   
  }
}

.summit-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  column-gap: 72px;
  row-gap: 90px;
  margin-bottom: 100px;
  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto;
    column-gap: 36px;
    row-gap: 45px;
    margin-bottom: 50px;
  }
  a {
    max-height: 18vw;
    &:hover {
      text-decoration: none;
    }
  }
  .summit-tiles {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    .image-holder {
      overflow: hidden;
      flex: 1;
      &:hover img {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
      }
      img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        height: 100%;
        object-fit: cover;
        
      }
    }
    .bottom-mask {
      height: 73px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $spanish-gray;
      font-size: 40px;
      line-height: 36px;
      font-family: $light-font;
      color: $white;
      @include media-breakpoint-down(lg) {
        height: 36px;
        font-size: 20px;
      }
    }
    &:hover {
      .bottom-mask {
        background-color: $asparagus-green;
      }
    }
  }
}

.news_report_section * {
  text-decoration: none !important;
  border: none;
}

.news_report_section:hover h2{
  text-decoration: underline !important;
}

.blog-detail-page{
  .btn.btn-back {
    background-color: #275D2D ;
  }
}