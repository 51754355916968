// media querry variable
$break-large-xxxl: 1700px;
$break-large-xxl: 1500px;
$break-large-xl: 1300px;
$break-large: 1200px;
$break-tab: 991px;
$break-medium: 767px;
$break-sm: 575px;
$break-s: 374px;

@mixin desktop-xxxl {
	@media (min-width:$break-large-xxxl) {
		@content;
	}
}

@mixin desktop-3xl {
	@media (max-width:$break-large-xxxl) {
		@content;
	}
}


@mixin desktop-xxl {
	@media (max-width: $break-large-xxl) {
		@content;
	}
}

@mixin desktop-xl {
	@media (max-width: $break-large-xl) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: $break-large) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: $break-tab) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $break-medium) {
		@content;
	}
}

@mixin mobile-sm {
	@media (max-width: $break-sm) {
		@content;
	}
}

@mixin mobile-s {
	@media (max-width: $break-s) {
		@content;
	}
}
