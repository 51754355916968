.highlights-slider {
  display: flex;
  position: relative;
  overflow: hidden;
  margin-bottom: 5%;
  @include desktop-xxl{
    margin-bottom: 8rem;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 42px;
  }
  &::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='1220' height='1000' viewBox='0 0 1220 1000' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='220' width='1000' height='1000' rx='500' fill='%231F4E99'/%3E%3Crect y='13' width='309' height='309' rx='154.5' fill='%231F4E99'/%3E%3C/svg%3E%0A");
    width: 1300px;
    height: 1300px;
    position: absolute;
    @include right(-150px);
    top: -150px;
    z-index: 1;
    background-size: contain;
    background-repeat: no-repeat;
   
    @include mobile{
      background-image: unset;
      background-color:#1f4e99;
      width: 65vw;
      top: 0px;
      @include right(0px);
    }
  
  }
  .btn-solid{
    margin-top: 2.5rem;
  }
  // &::after {
  //   // content: "";
  //   background-color: $cobalt-Blue;
  //   border: 200px;
  //   width: 1200px;
  //   height: 1000px;
  //   position: absolute;
  //   right: -150px;

  //   top: -158px;
  //   border-top-left-radius: 100%;
  //   border-bottom-left-radius: 100%;
  //   z-index: 1;
  //   @media (min-width: 1000px) and (max-width: 1885px) {
  //     width: 900px;
  //   }
  //   @media (min-width: 1885px) and (max-width: 2560px) {
  //     width: 1000px;
  //   }
  //   @media (min-width: 2560px) and (max-width: 3680px) {
  //     width: 1200px;
  //   }

  //   @include media-breakpoint-down(lg) {
  //     width: 500px;
  //     height: 400px;
  //     right: -150px;
  //     top: -70px;
  //   }
  // }
  .globe-mask {
    &::after {
      content: "";
      position: absolute;
      background: url("/assets/images/globe_white.svg");
      width: 400px;
      height: 400px;
      @include right(-128px);
      background-repeat: no-repeat;
      bottom: -29%;
      opacity: 0.16;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        width: 135px;
        height: 119px;
        @include right(-39px);
        bottom: -48px;
      }
    }
  }
  .highlights-swiper {
    width: 70%;
    @include custom-left(0);
    @include desktop{
      width: 100%;
    }
    .circle-mask {
      &::after {
        // content: "";
        background-color: $cobalt-Blue;
        border: 200px;
        width: 400px;
        height: 400px;
        position: absolute;
        @include right(178px);
        top: -220px;
        border-radius: 100%;
        z-index: 2;
        @include media-breakpoint-down(lg) {
          width: 114px;
          height: 114px;
          @include right(86px);
          top: -56px;
        }
      }
    }
    .swiper-highlights-pagination {
      text-align: left;
      @include left(12%);
      bottom: 30px;
      position: absolute;
      z-index: 1;
      @include media-breakpoint-down(lg) {
        bottom: 12px;
      }
      .swiper-pagination-bullet {
        width: 30px;
        height: 5px;
        border-radius: 0;
        background-color: $white;
        opacity: 0.5;
        @include media-breakpoint-down(lg) {
          width: 18px;
          height: 3px;
        }
        &.swiper-pagination-bullet-active {
          background-color: $white;
          opacity: 1;
        }
      }
    }
  }
  .swiper {
    height: 700px;
    @include media-breakpoint-down(lg) {
      height: 350px;
    }
  }

  .swiper-slide {
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-text-content {
    display: flex;
    align-items: center;
    position: absolute;
    @include right(8%);
    top: 0;
    z-index: 2;
    width: 569px;
    height: 100%;
    
    // @media (min-width: 1000px) and (max-width: 1885px) {
    //   width: 400px;
    // }
    @include desktop-xxl{
      width: 40%;
 
    }
    @include desktop-xxl{
      @include right(5%);
    }
    @include media-breakpoint-down(lg) {
      width: 189px;
      @include right(5%);
    }
  
    .highlights-content-list {
      .highlights-item {
        display: none;
        &:first-child {
          display: block;
        }
        .eyebrow-text {
          font-size: 21px;
          font-family: $bold-font;
          line-height: 1.2em ;
          color: rgba(255, 255, 255, 0.34);
          margin-bottom: 20px;
          @include media-breakpoint-down(lg) {
            font-size: 9px;
            line-height: 1.2em ;
            margin-bottom: 10px;
          }
        }
        h2 {
          font-size: 3rem;
          line-height: 1.2em;
          color: $white;
          font-family: $medium-font;
          margin-bottom: 40px;
          @include desktop-xxl{
            font-size: 2.5rem;
            line-height: 1.4em;
          }
          @include media-breakpoint-down(lg) {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }
        p {
          color: $white;
          line-height: 32px;
          @include media-breakpoint-down(lg) {
            line-height: 14px;
          }
        }
        .btn {
          background-color: $white;
          color: $davy-grey;
          border-color: $white;
          @include media-breakpoint-down(lg) {
            font-size: 10px;
          }
        }
      }
    }
  }
}
