/** Base **/
@import "./base/variables";


/** Mixins */
@import "./base/mixins";

@import "./base/colors";
@import "./base/typography";
@import "./base/responsive";
@import "./base/bootstrap";
@import "./base/layout";
@import "./base/reset";



/** font awesome **/
$fa-font-path: "../fonts/fontawsome";

@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/solid.scss";
@import "./fontawesome/brands.scss";
@import "./fontawesome/light.scss";

/** Component **/
@import "./components/header";
@import "./components/footer";
@import "./components/teaserCard";

/** Pages **/
@import "./pages/home";
@import "./pages/news_events";
@import "./pages/news_details";
@import "./pages/reports_details";
@import "./pages/open_report";
@import "./pages/summit";
@import "./pages/summit_forum";
@import "./pages/open_wish_summit";
@import "./pages/open_innovation";
@import "./pages/innovation";
@import "./pages/open_events_details";
@import "./pages/our_impacts";
@import "./pages/blogs";
@import "./pages//about_us";
@import "./pages/registration";
@import "./pages/partner_with_us";
@import "./pages/innovation_competition";
//RTL Styling
@import "./base/ar_layout";

@import "./base/desktop_responsive";
@import "./base/tablet_responsive";
@import "./pages/responsive";

@import "./base/globalizations";
//RTL Styling Responsive
// @import "./base/ar_layout";
// .news-events-container .container{
//     @include desktop-xxxl{
//         padding: 0px !important;
//     }
// }