.innovation-list {
  .section-content {
    padding: 0;
    .image-container {
      overflow: hidden;
      img {
        border-radius: 12px;
        box-shadow: none;
      }
    }
  }
}
