@import "../components/breadcrumb";

.topics-wapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3%;
  border-bottom: 1px solid $spanish-gray;
  margin-bottom: 4%;
  .btn {
    padding: 0px 1.5rem;
    font-size: 1rem;
    font-family: $regular-font;
    @include mobile{
      padding: 5px 10px;
      font-size: 10px;
      text-align: center;
    }
  }
  @include desktop {
    margin-bottom: 33px;
    padding-bottom: 33px;
    flex-direction: column;
  }
  
  .button-container {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      padding: 0;
      margin:  0px;
      flex-wrap: wrap;
      margin-bottom: -1rem;
      @include media-breakpoint-down(xxl) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 15px;
        padding-bottom: 20px;
      }
      li {
        @include custom-right(1.2rem);
        margin-bottom: 1rem;
        @media (min-width: 992px) and (max-width: 1680px) {
          @include custom-right(16px);
        }
        button {
          width: 100%;
        }
      }
    }
  }
}
.news_report_section {
  // padding-bottom: 165px;
  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }
  .news-card-wrapper {
    row-gap: 4%;
  }
  .news-card {
    margin-bottom: 5%;
    position: relative;
    &:nth-child(3n) {
      margin-bottom: 0;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }
    .image-container {
      height: 100%;
      img {
        border-bottom-left-radius: 60px;
        object-fit: cover;
        height: 100%;
        @include media-breakpoint-down(lg) {
          border-radius: 0;
        }
      }
    }
    .content-container {
      padding: 6% 7%;
      background-color: $spanish-gray-op0_1;
      height: 100%;
      border-top-right-radius: 60px;
      @include media-breakpoint-down(lg) {
        border-radius: 0;
        padding: 39px;
      }
      .eyrbrow-text {
        font-size: 18px;
        line-height: 1.2em;
        color: $teal-blue;
        font-family: $bold-font;
        margin-bottom: 3%;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          margin-bottom: 14px;
        }
      }
      .card-title {
        margin-bottom: 4%;
        @include media-breakpoint-down(lg) {
          margin-bottom: 17px;
        }
      }
      .description {
        font-family: $light-font;
        margin-bottom: 29px;
        @include media-breakpoint-down(lg) {
          margin-bottom: 15px;
        }
      }
    }
  }
}


.news-card:hover .btn {
  background-color: #39838b;
  color: #fff;
  text-decoration: underline;
}