@mixin custom-right($value) {
    html[dir="ltr"] & {
        margin-right: $value;
    }
    html[dir="rtl"] & {
        margin-left: $value;
    }
}
  


  

@mixin custom-left($value) {
    html[dir="ltr"] & {
        margin-left: $value;
    }
    html[dir="rtl"] & {
        margin-right: $value;
    }
}
  


/** Custom margin */

@mixin p-right($value) {
    html[dir="ltr"] & {
        padding-right: $value;
    }
    html[dir="rtl"] & {
        padding-left: $value;
    }
}

@mixin p-left($value) {
    html[dir="ltr"] & {
        padding-left: $value;
    }
    html[dir="rtl"] & {
        padding-right: $value;
    }
}
  

@mixin right($value){
    html[dir="ltr"] & {
        right: $value;
    }
    html[dir="rtl"] & {
        left: $value;
    }
}

@mixin left($value){
    html[dir="ltr"] & {
        left: $value;
    }
    html[dir="rtl"] & {
        right: $value;
    }
}




/** Custom padding */