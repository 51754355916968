.events-details {
  .page-titles-wrapper {
    padding-bottom: 5%;
    @include media-breakpoint-down(lg) {
      padding-bottom: 80px;
    }
  }
  .content-wrapper {
    // background: $spanish-gray-op0_1;
    padding: 166px 0;
    @include media-breakpoint-down(lg) {
      padding: 80px 0;
    }
    ul {
      li {
        list-style: disc;
      }
    }
    legend {
      color: $may-green;
      line-height: 25px;
    }
    .container {
      max-width: 1364px;
      .btn {
        height: 55px;
        background: $may-green;
        border-color: $may-green;
        @include media-breakpoint-down(lg) {
          height: 30px;
        }
        &:hover {
          color: $white;
        }
      }
    }
  }

  h3{
    font-size: 1.5rem;
    font-family: $medium-font;
  }
  p{
    line-height: 1.6em;
    font-size: 1.35rem;
    @include mobile{
      font-size: 1rem;
    }
  }
  .content-wrapper .container .btn {
    height: 45px;
  }
  .content-wrapper ul li {
    font-size: 1rem;
  }
  .content-wrapper legend {
    line-height: 1.2em;
  }
  legend {
    font-size: 1.1rem;
  }
}
