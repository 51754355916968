.header-news-card {
  padding: 60px 0;
  @include media-breakpoint-down(lg) {
    padding: 30px 0;
  }
  .images-container {
    padding: 1rem 0px 2.5rem;
    img {
      max-height: 450px;
      border-radius: 8px;
      box-shadow: 0 0px 5px rgba(0,0,0,.2);
      object-fit: cover;
      @include media-breakpoint-down(lg) {
        border-radius: 0;
      }
    }
  }
  .text-contant-container {
    display: flex;
    padding-bottom: 20px;
    height: 100%;
    .card-title {
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 3rem;
        line-height: 1.2em;
        color: $white;
        padding-top: 6.5rem;
        font-family: $medium-font;
        @include media-breakpoint-down(lg) {
          font-size: 28px;
          line-height: 34px;
          padding-top: 50px;
        }
      }
      .date {
        font-size: 20px;
        line-height: 1.2em;
        color: $white;
        margin-bottom: 90px;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .social-shares {
      margin-top: auto;
      h5 {
        font-size: 1rem;
        line-height: 1.2em;
        font-family: $bold-font;
        color: $white;
        padding-bottom: 1rem;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
          line-height: 20px;
          padding-bottom: 10px;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        li {
          
          @include custom-right(25px);
          @include media-breakpoint-down(lg) {
            @include custom-right(12px);
          }
          &:last-child {
            @include custom-right(0px);
          }
          a {
            font-size: 35px;
            color: $white;
            @include media-breakpoint-down(lg) {
              font-size: 18px;
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.content-section {
  .container {
    max-width: 1364px;
  }
  .two-column-image-container {
    display: flex;
    grid-gap: 1.875rem;
    margin: 0 auto;
    column-gap: 46px;
    row-gap: 0;
    padding: 95px 0;
    @include media-breakpoint-down(lg) {
      padding: 45px 0;
      column-gap: 20px;
    }
    img {
      height: 100%;
    }
    .caption {
      padding-top: 20px;
      font-size: 20px;
      line-height: 32px;
      @include media-breakpoint-down(lg) {
        padding-top: 10px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .quote-container {
    max-width: 1000px;
    margin: 95px auto;
    padding: 95px 102px 56px 138px;
    background: red;
    background: $spanish-gray-op0_1;
    color: $mughal-Green;
    font-family: $light-font;
    font-size: 32px;
    line-height: 42px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    position: relative;
    @include media-breakpoint-down(lg) {
      margin: 45px 0;
      padding: 45px 50px 28px 69px;
      font-size: 16px;
      line-height: 20px;
      border-top-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    &:before {
      background: url("/assets/images/quote2.jpg");
      content: "";
      width: 130px;
      display: block;
      height: 100px;
      background-repeat: no-repeat;
      top: 0;
      position: absolute;
      @include left(45px);
    }
    &:after {
      background: url("/assets/images/quote1.jpg");
      content: "";
      width: 130px;
      display: block;
      height: 100px;
      background-repeat: no-repeat;

      position: absolute;
      @include right(45px);
      bottom: -14px;
    }
  }
}

.similar-news {
  padding-bottom: 100px;
  @include media-breakpoint-down(lg) {
    padding-bottom: 50px;
  }
  h2 {
    font-size: 2rem;
    line-height: 1.2em;
    font-family: $bold-font;
    padding-bottom: 45px;
    color: #707070;
    @include media-breakpoint-down(lg) {
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 22px;
    }
  }
}
