.hero {
  height: 100vh;
  position: relative;
  &::after {
    content: "";
    background-image: url("/assets/images/globe_white.svg");
    position: absolute;
    width: 1000px;
    height: 1000px;
    bottom: -400px;
    @include right(-100px);
    -webkit-filter: invert(100%);
    filter: invert(10%);
    background-repeat: no-repeat;
    transform: rotate(271deg);
    z-index: -2;
    @include media-breakpoint-down(md) {
      width: 300px;
      height: 300px;
      bottom: -160px;
      @include right(-88px);
    }
  }
  @include media-breakpoint-down(md) {
    height: 500px;
  }
  .background-main {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    &::before {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      top: 0;
      @include right(0);
      bottom: 0;
      @include left(0);
      border-bottom-right-radius: 300px 300px;
    }
    @include media-breakpoint-down(md) {
      height: 500px;
    }
    .bg-source {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-bottom-right-radius: 300px 300px;
      @include media-breakpoint-down(md) {
        border-bottom-right-radius: 0;
        height: 500px;
      }
    }
  }
  .hero-content {
    min-height: 100vh;
    display: flex;
    // align-items: center;
    align-items: end;
    @include media-breakpoint-down(md) {
      min-height: 500px;
    }
    .intro-title {
      
      position: relative;
      font-family: $medium-font;
      font-size: 3.5rem;
      line-height: 1.2em;
      color: $white;
      margin-bottom: 16%;
      line-height:1.5em;
      padding: 2rem 3rem;
      @include mobile{
        margin-bottom: 8rem;
        width: 100%;
        padding: 1rem 1rem !important;
        line-height: 1.5em;
      }
      &::before{
        content: "";
        background: #575756;
        mix-blend-mode: multiply;
        position: absolute;
        @include left(0px);
        top: 0px;   
        width: 100%;
        height: 100%;
        z-index: -1;
        mix-blend-mode: multiply;
        opacity: 1;
        
      }
      @include desktop-xxl{
        font-size: 52px;
        line-height: 76px;
        padding: 1rem 2.4rem;
        margin-bottom: 12rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 36px;
      }
    }
    .scroll-icon-gif {
      position: absolute;
      bottom: 20px;
      display: flex;
      width: 100%;
      @include left( 0);
      justify-content: center;
      @include mobile{
        display: none;
      }
      img {
        width: 60px;
        height: 50px;
      }
    }
  }
}


.player-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
}


.video-btn {
  border: none;
  background: transparent;
  color: #fff;
	display: flex;
	align-items: center;
  svg {
    min-height: 2rem;
    min-width: 2rem;
    margin-bottom: 2px;
  }
  @include mobile{
    font-size: .8rem;
    svg{
      min-height: 1.2rem;
      min-width: 1.2rem;
    }
  }
}


