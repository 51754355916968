.about-us-page {
  .hero-image-container {
    height: 383px;
    overflow: hidden;
    margin-bottom: 93px;
    @include media-breakpoint-down(lg) {
      height: 191px;
      margin-bottom: 46px;
    }
    img {
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .eyebrow-title {
    line-height: 1.2em;
    font-size: 1.8rem;
    color: $may-green;
    margin-bottom: 1rem;
    font-family: $regular-font;
    @include desktop-xxl{
      font-size: 1.5rem;
    }
    @include mobile{
        font-size: 1.4rem;
    }
  }
  .content-container {
    .container {
      max-width: 92%;

      .title-wrapper {
      
        h1 {
          line-height: 1.2em;
          font-size: 2.8rem;
          font-family: $light-font;
          @include media-breakpoint-down(lg) {
            font-size: 30px;
            line-height: 36px;
          }
        }
        .sub-title {
          background: linear-gradient(95deg, #2281c1 0%, #2382b5 1%, #2e8e5b 85%, #339337 100%) 0% 0% no-repeat padding-box;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          font-size: 2.5rem;
          font-family: "DinProBold";
          width: 440px;
          text-align: center;
          padding: 235px 0;
          @include media-breakpoint-down(lg) {
            padding: 50px 0;
            width: 100%;
            font-size: 26px;
          }
        }
      }
      .image-colider {
        .holder1 {
          display: flex;
          justify-content: end;
          margin-bottom: 82px;
          @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
            justify-content: center;
          }
          img {
            // animation: crescendo 1.5s alternate infinite ease-in;
            animation-delay: 1s;
            @include media-breakpoint-down(lg) {
              animation: none;
            }
          }
        }
        .holder2 {
          position: relative;
          img {
            &:first-child {
              width: 387px;
              height: 266px;
              position: absolute;
              @include left(-50px);
              // animation: crescendo 1.5s alternate infinite ease-in;
              animation-delay: 2s;
              @include media-breakpoint-down(lg) {
                width: 100%;
                height: auto;
                position: relative;
                @include left(auto);
                animation: none;
              }
            }
            &:last-child {
              width: 268px;
              height: 344px;
              position: absolute;
              @include right(-100px);
              top: 65px;
              // animation: crescendo 1.5s alternate infinite ease-in;
              animation-delay: 3s;
              @include media-breakpoint-down(lg) {
                width: 100%;
                height: auto;
                position: relative;
                @include right(auto);
                animation: none;
              }
            }
          }
        }
      }
      ul {
        margin: 0;
        li {
          list-style: disc;
          padding-bottom: 20px;
          @include media-breakpoint-down(lg) {
            font-size: 16px;
            padding-bottom: 18px;
          }
        }
      }
    }
  }
  
}

@keyframes crescendo {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.image-slider {
  margin-bottom: 200px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 50px;
  }
 
  .title {
    background: linear-gradient(95deg, #2281c1 0%, #2382b5 1%, #2e8e5b 85%, #339337 100%) 0% 0% no-repeat padding-box;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-size: 2.5rem;
    font-family: "DinProBold";
    width: auto;
    text-align: left;
    padding-top: 5%;
    padding-bottom: 5%;
    @include media-breakpoint-down(lg) {
      font-size: 26px;
      width: 100%;
      padding: 50px 0;
    }
  }
}

.swiper-slide {
  .slider-container {
    display: flex;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
    .image-content {
      img {
        border-radius: 12px;
      }
    }
    .text-content {
      @include p-left(0px);
      @include media-breakpoint-down(lg) {
        padding: 20px;
      }
      @include custom-left(5rem);
      h2 {
        font-size: 1.8rem;
        line-height: 1.2em;
        color: $may-green;
        margin-bottom: 1.5em;
        font-family: $regular-font;
        @include desktop-xxl{
          font-size: 1.5rem;
        }
        @include mobile{
          font-size: 1.4rem;
          margin-bottom: 1.5em;
        }
      }
    }
  }
  [data-animate] {
    opacity: 0;
    transition: all 0.8s ease-out;
  }
  &.animeslide-slide.swiper-slide-active .animeslide-content {
    opacity: 1;
    transform: none;
  }
  &.animeslide-slide.swiper-slide-active .animeslide-content {
    transition-delay: 0.6s;
  }

  .image-content,
  .text-content {
    width: 50%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}

.paginations-container {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  .swiper-pagination {
    position: relative;
    width: 88% !important;
    border-radius: 12px;
    height: 7px !important;
    background-color: $spanish-gray-op0_4;
    .swiper-pagination-progressbar-fill {
      border-radius: 12px;
      background-color: $spanish-gray;
    }
  }
  .image-slider__fraction {
    width: 10%;
    display: flex;
    justify-content: space-around;
  }
}

.about-us-page{
  .page-titles-wrapper{
    p{
      max-width: 80% !important;
    }
  }

  .swiper-pagination{
    background: #cae3c7 !important;
  }
}

.green-title {
  color: #4FA347 ;
}

.mobile-nav{
  display: none;
  @include mobile{
    display: block;
  }
}
.eyebrow-title {
  margin-top: 5rem;
}



.paginations-container .swiper-pagination .swiper-pagination-progressbar-fill {
  background-color: $may-green;
}


.image-slider__current {
  color: $may-green;
}
