.search_filters {
  display: flex;
  justify-content: space-between;
  .dropdown {
    button {
      border: 0;
      font-size: 1rem;
      line-height: .8em;
      color: $spanish-gray;
      font-family: "DinNextLTRegular";
      height: 50px;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 15px;
      }
      &::after {
        content: "\f883";
        font-family: "Font Awesome 6 Pro";
        font-size: 16px;
        border: 0;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
      }
      &:hover {
        color: $white;
      }
    }
  }

}

.divider {
  hr {
    margin: 4rem 0;
    @include media-breakpoint-down(lg) {
      margin: 32px 0;
    }
  }
}
