.btn-arrow-container {
    margin-top: -4.5rem;
    padding-top: 2rem;
}
  
@include desktop-xl{
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo {
        max-width: 280px;
    }
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo {
        margin-left: auto;
        margin-right: auto;
    }
}

.news-events-container .news-events-slider .slide-inner {
    /* width: 40%; */
    width: 46%;
  }
  
  .news-events-container .news-events-slider .slide-inner img {
    /* height: 400px; */
    height: 550px;
  }
  
  .news-events-container .news-events-slider .slider-content-wrapper {
    /* height: 400px; */
    height: 550px;
  }
  
  .news-events-container .news-events-slider .slider-content-wrapper {
    /* width: 55%; */
    width: 50%;
  }
  
  .d-block.swiper-slide.swiper-slide-active {
    /* margin-right: 4rem; */
    // margin-right: 6rem;
    @include custom-right(10rem);
  }
  @include desktop-xl{
    .d-block.swiper-slide.swiper-slide-active {
        /* margin-right: 4rem; */
        // margin-right: 6rem;
        @include custom-right(5rem);
      }
  }



.footer {
    padding-top: 5%;
}
  
.footer .footer-partners-list {
    padding-bottom: 5%;
}
  
.footer .footer-section-title {
    line-height: 1.2em;
    padding-bottom: 2%;
}
  



.dropdown-menu-end {
    background: #e9e9ed;
}
  
  /* main.css | https://wish.a101.co/wp-content/themes/wish/dist/css/main.css */
  
  .divider hr {
    position: relative;
    z-index: -1;
  }
  


.latest-research .section-content .content-list img {
    height: 100%;
    object-fit: cover;
}
@include desktop-xxl{

    .highlights-slider .globe-mask::after {
        width: 380px;
        height: 380px;
        opacity: 0.1;
    }
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 90%;
    }


/* Inline #8 | https://wish.a101.co/news/ */

.news-card-wrapper .news-card .image-section img {
    height: 100%;
    width: 100%;
  }
  
.news-card-wrapper .news-card .description {
    font-size: 1.2rem;
}
.btn {
    padding: 0px 25px;
}

.news-card-wrapper .news-card .title-date .date {
    font-size: 16px;
}

.btn.btn-v2 {
    height: 40px;
}

.news-card-wrapper .news-card .title-date {
    margin-bottom: 13px;
}

    .news-card-wrapper .news-card .image-section {
        height: 250px;
      
    }
    .news-card-wrapper .news-card .image-section img {
        width: 100%;
    }
    
 
  
  
    .latest-research.home-section .section-content .content-list img {
        height: 390px;
    }
    
    .latest-research .section-content .content-list p {
        padding-top: 8%;
    }
  
    .section-title .title.bg-pulse {
        margin-bottom: 0px;
    }
   

    .news-events-container .news-events-slider.desktop {
        // margin-right: -5%;
        @include custom-right(-5%);
    }

    // .latest-research .section-title .title.bg-pulse .pulse-img {
    //     margin-bottom: -10px;
    // }
  
    .latest-research .section-title .sub-title {
        max-width: 100%;
    }
    .innovations {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .view-all-holder {
        margin-top: 2rem;
    }
    .section-title .title.bg-pulse {
        align-items: end;
    }
    .innovations .section-content .content-list .image-card {
        max-height: 240px;
     }

    .news-events-container .news-events-slider .slider-content-wrapper {
        height: 500px;
    }
    
    .news-events-container .news-events-slider .slide-inner img {
        height: 500px;
    }
    
    .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content h2, .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content .h2 {
        margin-bottom: 5%;
    }
    
    h2, .h2 {
    font-size: 1.6rem;
    }


    .hero .background-main::before {
        border-bottom-right-radius: 200px 200px;
    }
    
    .hero .background-main .bg-source {
        border-bottom-right-radius: 200px 200px;
    }
  
    
    .news-events-container .news-events-slider .slider-content-wrapper {
        width: 65%;
    }

    .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content {
        width: 50%;
    }

    .section-title .title.bg-pulse .text {
        margin-bottom: 1.5rem;
    }
    
    .news-events-container .news-events-slider .slide-inner {
        display: block;
        width: 45%;
        img{
            object-fit: cover;
        }
    }
    
    .highlights-slider .swiper {
        height: 580px;
    }
    .highlights-slider::after {
        width: 80vw;
    }
    .our-impacts .section-content .content-list .image-card {
        max-height: 350px;
    }





    .header-news-card .text-contant-container .card-title h1, .header-news-card .text-contant-container .card-title .h1 {
        font-size: 52px;
        line-height: 1.2em;
        padding-top: 5.5rem;
        margin-bottom: .8rem;
    }
  
    .header-news-card .text-contant-container .card-title .date {
        font-size: 1rem;
    }
  
    .header-news-card .text-contant-container .social-shares h5, .header-news-card .text-contant-container .social-shares .h5 {
        font-size: 1rem;
    }
    
    .header-news-card .text-contant-container .social-shares ul li a {
        font-size: 25px;
    }
    

    
    .header-news-card .images-container {
        padding: 1rem 0px 2rem;
    }
  



    p {
        font-size: 1rem;
    }
  
    .content-section .container {
        max-width: 90%;
    }
  
    .bg-branding::before {
        width: 450px;
        height: 450px;
        bottom: 5rem;
    }
  
   .bg-branding::before {
    @include right(-180px);
  }
  

    .content-section p {
        padding: 0px 110px;
    }


    .content-section img {
        padding: 0px 110px;
    }

    .content-footer {
        margin: 3rem 110px 4rem;
    }

    .social-shares i {
        font-size: 1.5rem;
    }


    .content-footer .social-shares h5, .content-footer .social-shares .h5 {
        font-size: 1rem;
    }

    .content-footer .scroll-to-top .scroll-top {
        font-size: 16px;
    }

    .content-footer .scroll-to-top .scroll-top i {
        font-size: 12px;
    }

    .content-footer {
        padding: 11px 0 20px;
    }


    .similar-news h2, .similar-news .h2 {
        font-size: 1.8rem;
        line-height: 1.2em;
        padding-bottom: 25px;
    }

    .footer .footer-menu-container .wrapper .footer-links-section ul li a {
        font-size: 1.3rem;
    }
    
    .similar-news h2, .similar-news .h2 {
        font-size: 1.8rem;
        line-height: 1.2em;
        padding-bottom: 2.2rem;
    }

    .latest-research .section-content {
        grid-gap: 2rem;
    }


    .teasetr-card .text-content {
        padding: 0 3rem 0 3rem;
    }
  
    .teasetr-card {
        padding: 4rem 4rem 3.4rem;
    }
    
    .teasetr-card .text-content h2, .teasetr-card .text-content .h2 {
        font-size: 1.8rem;
        line-height: 1.2em;
    }
    
    .teasetr-card .text-content .year-text {
        font-size: 1rem;
    }


    

    .text-wrapper  {
        padding: 0px 110px;
        .content-footer {
            margin: 3rem 0px 8rem;
        }
    }
   
    .download-container i {
        font-size: 1.4rem;
    }

    .back-button-container {
        margin-bottom: 3rem;
    }
  
    .footer .footer-menu-container .wrapper {
        padding-bottom: 4%;
    }
    .view-all-holder {
        padding-top: 1rem;
    }

    //


  
    
  
    .highlights-slider {
        margin-bottom: 6rem;
    }
  
    .btn-arrow-container {
        margin-top: -5.5rem;
        padding-top: 0rem;
    }
    
    .view-all-holder {
        margin-top: 0rem;
        padding-bottom: 1rem;
    }
  
    .section-title .sub-title {
        margin-bottom: 1rem;
    }
  
    .news-events-container .cta__slider-arrows .wrap .swiper-btn-next, .news-events-container .cta__slider-arrows .wrap .swiper-btn-prev {
        width: 45px;
        height: 45px;
    }
    
    .news-events-container {
        margin-bottom: 5%;
    }




    /***
    *
    * Magic Style for slider
    */
    .news-events-container .news-events-slider .slider-content-wrapper {
      width: 59%;
    }
    .news-events-container .news-events-slider .slide-inner {
      width: 59%;
    }
    .news-events-container .news-events-slider.desktop {
      @include custom-right(-6rem);
    }
  
  
    .d-block.swiper-slide.swiper-slide-active {
        @include custom-right(10rem);
    }
        
    .d-block.swiper-slide.swiper-slide-next {
        @include custom-right(-13rem);
    }
    
    .news-events-container .news-events-slider .slider-content-wrapper {
        width: 58%;
    }
    .slider-content-wrapper    h2, .h2 {
        font-size: 1.3rem;
    }
  
    .section-title .title.bg-pulse .text {
        font-size: 2.5rem;
    }
    .section-title .title {
        padding-bottom: 23px;
    }

    .section-title .title.bg-pulse .text {
        font-size: 2.5rem;
        }
    // .latest-research .section-title .title.bg-pulse .pulse-img {
    //     margin-left: -9rem;
    // }
  

   


    .footer {
        padding-top: 5%;
    }
    .footer .footer-section-title {
        font-size: 3rem;
        padding-bottom: 2rem;
    }
    .footer .footer-partners-list {
        padding-bottom: 5%;
    }




    .footer .footer-partners-list .partners-item img {
      max-width: 68%;
    }
  
    .footer .footer-partners-list .partners-item {
        @include p-right(30px);
        @include p-left(30px);
    }
  

    .hero-banner-wrapper::after {
        top: -190px;
    }
    .hero-banner-wrapper::after {
        @include right(-20%);
    }
    .hero-banner-wrapper::after {
        width: 75%;
        height: 185%;
    }

    .summit-list .summit-tiles .bottom-mask {
        height: 55px;
        font-size: 30px;
    }
    .hero-banner-wrapper {
        margin: 0 0 100px 0;
    }
  
    .summit-list {
        column-gap: 60px;
        row-gap: 60px;
    }

    .open-wish-summit .content-wrapper .section-summit-list .summit-title {
        font-size: 52px;
    }

    .open-wish-summit .content-wrapper .sticky-sie-bar {
        width: 350px;
    }
    .open-wish-summit .content-wrapper .sticky-sie-bar ul li {
        margin-bottom: 15px;
    }
    
    .open-wish-summit .content-wrapper .sticky-sie-bar ul li::before {
        width: 70px;
        height: 50px;
        background-size: contain;
    }

    .open-wish-summit .page-titles-wrapper {
        padding-bottom: 3%;
    }


    .open-wish-summit .section-title .sub-title {
        max-width: 65%;
    }
    .open-wish-summit .section-title .sub-title {
        font-size: 1rem;
    }
    .section-peoples-list .people-card .bio {
        font-size: .8rem;
        line-height: 1.2em;
    }
    .section-peoples-list .people-card h4, .section-peoples-list .people-card .h4 {
        font-size: 1rem;
        line-height: 1.2em;
        font-family: "DinNextLTNormal", arial, serif;
        font-weight: 700;
    }
    .open-wish-summit .content-list p {
        display: inline;
    }
    .open-wish-summit .content-wrapper .section-summit-list .section-content .content-list p {
        padding-top: 1rem;
    }
    .open-wish-summit .content-wrapper .section-summit-list {
        padding-top: 10%;
    }
    .open-wish-summit .content-wrapper .section-summit-list .section-content .content-list .image-card {
        height: 200px;
    }
    .open-wish-summit .content-wrapper .section-summit-list .section-content .content-list p {
        padding-top: 21px;
    }






    //


    .eyebrow-title {
        margin-top: 0px;
    }
  
    .about-us-page .content-container .container .image-colider .holder2 img:last-child {
        @include right(-70px);
    }
    
    .about-us-page .content-container .container .image-colider .holder2 img:first-child {
        @include left(-85px);
    }
    
    .about-us-page .content-container .container .title-wrapper .sub-title {
        font-size: 2.8rem;
        width: 400px;
        padding: 15rem 0px 15rem;
    }
    
    .about-us-page .content-container .container .image-colider .holder1 {
        margin-bottom: 15%;
    }
  
  
    .image-colider {
        margin-top: 3.5rem;
    }
    
    .image-colider img {
        max-width: 70%;
        height: auto !important;
    }
    .image-slider {
        margin-bottom: 8%;
    }


    .blog-detail-page{

        .content-wrapper .container {
            padding: 0px 141px;
        }
    
    
        .box-content-wrapper .box-content-left {
            padding-left: 110px;
        }
    
        .box-content-wrapper .box-content-left h1, .box-content-wrapper .box-content-left .h1 {
            font-size: 42px;
            margin-bottom: 1rem;
        }
    
        .back-button-container {
            margin-bottom: -3rem;
        }
        .content-wrapper .container {
            padding: 0px 180px;
            
        }


        .blog-detail-page .content-wrapper .container {
            padding: 0px 180px;
        }
  
        .content-wrapper{
            margin: 1rem 0px;
        }
        
        .content-wrapper ul {
            list-style: unset;
            font-size: 1rem;
        }
    
        .content-wrapper ul li {
            margin-bottom: .5em;
        }
  
        .content-footer {
            margin: 3rem 0px 4rem;
        }
        .content-wrapper img{
            margin: 1rem 0px 1.5rem;
        }
        

    }

    .blog-detail-page .content-wrapper .container legend {
        line-height: 1.2em;
        font-size: 1rem;
    }
    .pb-165 {
        padding-bottom: 8%;
    }

    .blog-detail-page .back-button-container {
        z-index: 100;
        position: relative;
    }
   

  
}
.content-list:hover .btn.btn-outline {
    text-decoration: none !important;
}

@include desktop{
    .bg-white.mobile-copy {
        margin-top: -1px;
    }

    .modal-content {
        width: 94%;
        margin: auto;
    }

    .image-colider {
        margin-top: 0px !important;
    }
}



/* main.css | https://wish.a101.co/wp-content/themes/wish/dist/css/main.css */
.latest-research.home-section{
.btn.btn-solid:hover {
    color: $white;
    background-color: #1F4E99;
  }
}



/* main.css | https://wish.a101.co/wp-content/themes/wish/dist/css/main.css */
@media screen and (max-height: 720px) {
    .site-header .menu-overlay .menu-list li a {
        font-size: 1.1em;
    }
    .site-header .menu-content .social-share-container .soacial-share-list li a {
        max-width: 90%;
    }
    .site-header .menu-content .social-share-container .soacial-share-list li a {
        font-size: 1rem;
    }
    .site-header .menu-content .social-share-container {
        margin-bottom: 5%;
    }
    .site-header .menu-content .search-container-home .search-input-container-home .search-input {
        height: 40px;
    }
    .site-header .menu-content .search-container-home .search-icon-btn {
        width: 33px;
        height: 33px;
    }
    .site-header .menu-content {
        padding-top: 10%;
    }
    .site-header .menu-overlay .menu-list li {
        margin-bottom: 4%;
    }

    //

    .site-header .main-logo img {
      max-height: 60px;
    }
  
    .site-header {
        padding-top: 1.5%;
        padding-bottom: 1.5%;
    }
  
    .site-header .search-overlay .search-overlay-header .search-input-section h1, .site-header .search-overlay .search-overlay-header .search-input-section .h1 {
        font-size: 2.5rem;
    }
  
    .site-header .search-overlay .search-overlay-header .search-input-section .search-input-holder .search-input {
        height: 45px;
    }
  
    .site-header .search-overlay .search-overlay-header .search-input-section {
        max-width: 54%;
    }
  
    .site-header .search-overlay .search-overlay-header .search-input-section .search-input-holder .search-icon-btn {
        width: 35px;
        height: 35px;
    }
  
    #searchbutton2 i {
        font-size: 15px;
    }
  
    .site-header .search-overlay .search-overlay-header {
        min-height: 320px;
        padding-bottom: 35px;
    }
  
    .site-header .search-overlay .search-overlay-content {
        padding-top: 45px;
    }
    
    .site-header .search-overlay .search-overlay-content .container h2, .site-header .search-overlay .search-overlay-content .container .h2 {
        margin-bottom: 2%;
    }
  
    .site-header .search-overlay .search-overlay-content .container ul li {
        font-size: .9rem;
        line-height: 1.3em;
    }

    .hero .hero-content .intro-title {
      font-size: 2.8rem;
      line-height: 1.2em;
    }
  
    .video-btn {
        font-size: 1rem;
    }
  
    .video-btn svg {
        min-height: 1rem;
        min-width: 1rem;
    }
        
    .hero .hero-content .scroll-icon-gif img {
        width: 50px;
        height: 40px;
    }
  
    .highlights-slider .swiper-text-content .highlights-content-list .highlights-item h2, .highlights-slider .swiper-text-content .highlights-content-list .highlights-item .h2 {
        font-size: 2.2rem;
    }
  
    .highlights-slider .swiper-text-content .highlights-content-list .highlights-item p {
        line-height: 1.2em;
    }
  
    .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content p {
        font-size: .9rem;
    }
  
    .slider-content-wrapper h2, .h2 {
      font-size: 1.1rem;
    }
    .btn {
      padding: 0px 20px;
    }
    .footer .footer-section-title {
      font-size: 2rem;
    }
  
    .btn {
        font-size: .8rem;
    }
  
    .btn.btn-outline {
        height: 2.2rem;
    }
  
    .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content .eyrbrow-text-wrapper .project-title {
        font-size: .8rem;
        line-height: 1em;
    }
  
    .news-events-container .news-events-slider .slider-content-wrapper .slide-content-item .slide-content .eyrbrow-text-wrapper .project-date {
        font-size: .8rem;
        line-height: 1em;
    }
  
    .news-events-container .news-events-slider .swiper-slide h5, .news-events-container .news-events-slider .swiper-slide .h5 {
        font-size: 1rem;
        line-height: 1.2em;
    }
  
    .btn-arrow-container {
        margin-top: -2rem;
    }
    
    .our-impacts .section-title {
        margin-bottom: 0rem;
    }
  
    .section-title {
        padding-bottom: 1rem;
    }
  
    .our-impacts .section-content .content-list p {
        font-size: 1.1rem;
    }
  
    .our-impacts .section-content .content-list .image-card {
        margin-bottom: 2%;
    }
  
    .our-impacts .section-content {
        grid-gap: 2rem;
    }
  
    .our-impacts .view-all-holder {
        padding-bottom: 4rem;
    }
  
    .footer .footer-partners-list {
        max-width: 80%;
    }
    
    .footer-logo img {
        max-width: 300px;
    }



    .search-container .search-input-container .search-input {
        height: 35px;
    }
  
    .search-container .search-input-container .search-input {
        padding: 12px 20px;
    }
    
    .search-container .search-icon-btn {
        @include right(15px);
    }
  
    .row{
        @include custom-right(0px);
        @include custom-left(0px);
    }
    
}


@include desktop{
    .blog-detail-page .box-content-wrapper .box-content-left h1, .blog-detail-page .box-content-wrapper .box-content-left .h1 {
        font-size: 1.8rem;
    }
    .open-wish-summit .content-wrapper .section-summit-list .summit-title {
        font-size: 1.8rem;
    }
    .similar-news h2, .similar-news .h2 {
        font-size: 1.4rem;
      }
 
   .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo a {
      display: block;
    }
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo a:last-child {
        max-width: 100% !important;
    }
  
}
