.feature-cards {
  padding: 5% 0;
  @include desktop-xxl{
    padding: 120px 0;
  }
  @include media-breakpoint-down(xl) {
    padding: 82px 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 40px 0;
  }
  @include mobile{
    padding: 42px 0;
  }
  .container {
    @include media-breakpoint-down(lg) {
      @include p-left(0);
      @include p-right(0);
    }
    .slick-slide {
      @include p-right(15px);
      @include desktop-xxl{
        max-width: calc(100%/3 - 15px);
      }

      &:last-child {
        @include p-right(0);
      }
    }
  }
  .serve--card {
    position: relative;
    height: clamp(550px, 50vh, 550px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    @include desktop-xxl{
      height: clamp(440px, 50vh, 550px);
    }
    @include media-breakpoint-down(lg) {
      height: clamp(300px, 50vh, 300px);
    }
    cursor: pointer;
    .card-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      @include left(0);
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        @include left(0);
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .card-info-wrapper {
      transform: translateY(100%);
      position: relative;
      z-index: 1;
      height: 100%;

      color: #fff;
      text-align: center;
      overflow-y: hidden;
      &.color-bg-1 {
        background-color: $dark-pastel-blue;
      }
      &.color-bg-2 {
        background-color: $asparagus-green;
      }
      &.color-bg-3 {
        background-color: $teal-blue;
      }
      &.color-bg-4{
        background-color: $saddle-brown;
      }

      a *{
        color: $white !important;
      }
    }
    .card-info {
      position: absolute;
      width: 100%;
      padding: 10px 40px;
      display: block;
      @include media-breakpoint-down(xl) {
        padding: 10px 20px;
      }
    }
    .card-title {
      font-family: $light-font;
      font-size: 25px;
      line-height: 36px;
      padding-top: 5px;
      padding-bottom: 30px;
      color: #ffff;
      justify-content: center;
      width: 100%;
      @include desktop-xxl{
        padding-bottom: 10px;
      }
      @media (min-width: 1200px) and (max-width: 1680px) {
        font-size: 27px;
      }
      @include media-breakpoint-down(xl) {
        font-size: 22px;
        line-height: 20px;
      }
    }
    .card-desc {
      font-family: $light-font;
      font-size: 22px;
      line-height: 36px;
      margin-top: 1rem;
      opacity: 0;
      @include desktop-xxl{
        font-size: 18px;
        line-height: 26px;
      }
      @include media-breakpoint-down(xl) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .card-action {
      margin-top: 1rem;
      opacity: 0;
      a {
        text-decoration: none;
        color: #fff;
        display: inline-block;
        font-family: "DM Sans";
        border: 1px solid #fff;
        border-radius: 8px;
        padding: 1rem 1.5rem;
      }
    }
  }
}


.btn-arrow-container {
  margin-top: -4rem;
  background: #fff;
  z-index: 100000;
  position:relative;
  @include mobile{
    margin-top: -0.5rem;
  }
}