.image_text_container {
  .image-holder {
    img {
      border-radius: 12px;
    }
  }
  .text-content {
    @include p-left(53px);
    @include media-breakpoint-down(lg) {
      @include p-left(0);
      padding-top: 20px;
    }
    h3 {
      color: $may-green;
    }
  }
}
