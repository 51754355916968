@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');


html[dir="rtl"]{

    .menu-content {
        padding-top: 10%;
    }
    #registration-page .about-wish-section img {
        float: left;
    }
    .btn.btn-primary.bg-blue svg {
        transform: scaleX(-1);
    }

    .why-attend .container::after {
        transform: scaleX(-1);
    }
    .register-button svg {
        transform: scaleX(-1);
    }
    #registration-page .oppertunities-section .container::after {
        transform: scaleX(-1);
    }
    .news-events-container .news-events-slider .slide-inner .media-wrapper {
        border-top-right-radius: unset;
        border-bottom-left-radius: unset;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
    }
    .news-events-container .news-events-slider .slider-content-wrapper {
        border-top-right-radius: unset;
        border-bottom-left-radius: unset;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
    }
    .news-events-container .news-events-slider .swiper-slide-active .slide-inner .media-wrapper {
        border-top-right-radius: unset;
        border-top-left-radius: 0;

      }
    body {
        h1 , h2, h3 ,h4,h5,h6 ,p  ,div ,span,a,li,ul,ol,button{
            font-family: "Cairo", sans-serif !important;
        }
    }
  
    // Home page

    // .highlights-slider .swiper-text-content {
  
    //     left: 8%;
    // }
  
    // .highlights-slider::after {
  
    //     left: -40vw;
    // }
  
    .highlights-slider .globe-mask::after {
     
        // left: -128px;
        transform: scaleX(-1);
    }
    .hero .background-main .bg-source {
        border-bottom-right-radius: unset;
        border-bottom-left-radius: 300px 300px;
    }
    .hero .background-main::before {
        border-bottom-right-radius: unset;
        border-bottom-left-radius: 300px 300px;
    }

    .highlights-slider .highlights-swiper .swiper-highlights-pagination {
        // left: unset;
        text-align: right;
        
    }
    // .dropdown-toggle::after {
    //     margin-left: unset;
    //     @include custom-right( 0.255em);
    // }
  
    .fa-arrow-left::before {
        display: block;
        transform: scaleX(-1);
    }

    .pulse-img img {
        transform: scaleX(-1);
    }
    .highlights-slider::after {
        @include left(-40vw);
    }
    .highlights-slider::after {
        transform: scaleX(-1);
    }

    .open-wish-summit .content-wrapper .sticky-sie-bar {
        border-bottom-right-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: 95px;
        border-top-left-radius: 12px;
    }
    
    .open-wish-summit .content-wrapper .sticky-sie-bar ul li:hover span {
        transform: translateX(-20px);
    }

    .dropdown-toggle::after {
        margin-left: unset;
        margin-right: 0.255em;
    }

    .fa-light.fa-angle-right {
        transform: rotate(180deg);
    }
    .fa-light.fa-angle-left {
        transform: rotate(180deg);
    }
    .site-header .menu-overlay .menu-list li a:hover::after {
        left: -30px;
    }
    .site-header .menu-overlay .menu-list li a:hover::after {
        transform: rotate(180deg);
    }
    .news_report_section .news-card .content-container {
        border-top-right-radius: unset;
        border-top-left-radius: 60px;
    }
    .news_report_section .news-card .image-container img {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: 60px;
    }
    .fa-solid.fa-caret-right {
        transform: rotate(180deg);
    }
    .fa-solid.fa-caret-left {
        transform: rotate(180deg);
    }
    .row.pb-165.bg-globe-rit::before {
        transform: scaleX(-1);
    }

    @include mobile{
       .hero .background-main .bg-source {
            border-bottom-left-radius: 100px 100px;
            }
        .hero .background-main::before {
            border-bottom-left-radius: 100px 100px;
        }
    }
 
}

.our-impacts .section-content .content-list .image-card {
    max-height: 450px;
    height: 100%;
}