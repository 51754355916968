.breadcrumb-wrapper {
  padding-bottom: 3.5%;
  // @include media-breakpoint-down(lg) {
  //   padding-bottom: 33px;
  // }
  .breadcrumb {
    br {
      display: none;
    }
    ol {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      li {
        @include p-right(35px);
        display: flex;
        align-items: center;
        font-size: .8rem;
        line-height: 1.2em;
        justify-content: center;
        position: relative;
        @include media-breakpoint-down(lg) {
          @include p-right(16px);

        }
        &::after {
          content: "";
          position: absolute;
          width: 22px;
          height: 2px;
          @include right(6px);
          background-color: $spanish-gray;
          @include media-breakpoint-down(lg) {
            width: 12px;
            @include right(12px);
          }
        }
        &:last-child {
          @include p-right(0);
          &::after {
            display: none;
          }
        }
        a {
          color: $spanish-gray;
          font-size: 1.1rem;
          font-family: $light-font;
          line-height: 1.2em;
          @include media-breakpoint-down(lg) {
            font-size: 12px;
            line-height: 12px;
          }
          i {
            @include p-right(10px);
            font-size: 16px;
            @include media-breakpoint-down(lg) {
              font-size: 10px;
              @include p-right(5px);
            }
          }
        }
      }
    }
  }
}
