// Fonts
$regular-font: "DinNextLTRegular", arial, serif;
$bold-font: "DinNextLTBold", arial, serif;
$light-font: "DinNextLTLight", arial, serif;
$medium-font: "DinNextLTMedium", arial, serif;

$grid-gutter-width: 4rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1680px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1420px,
  xxxl: 1680px,
);


// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1638px,
// );

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1198px,
//   xxl: 1680px,
// );

$modal-xl: 1680px;
