.box-content-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 165px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 0;
    padding-bottom: 80px;
  }
  .box-content-left {
    @include p-left(133px);
    @include media-breakpoint-down(lg) {
      @include p-left(0);
    }
    h1 {
      font-size: 3rem;
      line-height: 1.2em;
      font-family: $medium-font;
      @include desktop-xxl{
        font-size: 52px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 1.8rem;
        border-bottom: .5rem;
      }
    }
    .name {
      font-size: 24px;
      line-height: 40px;
      font-family: $light-font;
      padding-bottom: 16px;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 20px;
        padding-bottom: 10px;
      }
    }
    .bio {
      font-size: 20px;
      line-height: 40px;
      font-family: $light-font;
      padding-bottom: 62px;
      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 20px;
        padding-bottom: 30px;
      }
    }
    .link-out a {
      color: $may-green;
      text-decoration: underline;
      @include media-breakpoint-down(lg) {
        padding-bottom: 30px;
      }
    }

    .social-shares {
      position: absolute;
      bottom: 0;
      @include media-breakpoint-down(lg) {
        position: relative;
      }
      h5 {
        font-size: 1rem;
        line-height: 1.2em;
        margin-bottom: 10px;
        font-family: $bold-font;
        color: $davy-grey;
        @include media-breakpoint-down(lg) {
          font-size: 12px;
          line-height: 20px;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        li {
          @include custom-right(0.8rem);
          list-style: none;
          @include media-breakpoint-down(lg) {
            @include custom-right(12px);
          }
          &:last-child {
            @include custom-right(0);
          }
          a {
            font-size: 32px;
            @include media-breakpoint-down(lg) {
              font-size: 16px;
            }
            &:hover {
              text-decoration: none;
            }
            i{
              font-size: 1.5rem;
            }
            i.fa-facebook {
              color: #3c5a9a;
            }
            i.fa-twitter {
              color: #03a9f4;
            }
            i.fa-whatsapp {
              color: #4caf50;
            }
          }
        }
      }
    }
  }
}

.breif-wrapper {
  background: $spanish-gray-op0_1;

  padding: 166px 0;
  @include media-breakpoint-down(lg) {
    padding: 80px 0;
  }
  .breif-logo {
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
  }
  .container {
    max-width: 1364px;
  }
  .title {
    font-size: 3rem;
    line-height: 1.2em;
    font-family: $light-font;
    padding-bottom: 50px;
    color: $may-green;
    @include desktop-xxl{
      font-size: 52px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 16px;
      line-height: 21px;
      padding-bottom: 25px;
    }
  }
  p{
    color: #707070;
  }
  .video-container {
    .video-obj,
    .youtube-player {
      height: 800px;
      @include media-breakpoint-down(lg) {
        height: 400px;
      }
    }
  }
}
