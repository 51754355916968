

.our-impacts{
    .view-all-holder {
        margin-top: 0px;
    }
}
@include desktop{
    .topics-wapper .button-container ul{
        display: flex;
    }
   
  
    .topics-wapper .button-container ul li {
        margin-bottom: 0px;
    }
  
    .section-title {
      padding-bottom: 1rem;
    }
    
    .search-container {
        width: 45%;
    }
  
    .topics-wapper {
        align-items: flex-start;
    }
    .topics-wapper .button-container ul {
      margin-bottom: 1rem;
    }
    
    .news-card-wrapper .news-card .description {
        font-size: 1.1rem;
        margin-bottom: 5%;
    }
    
    .news-card-wrapper .news-card .title-date {
        margin-bottom: .5rem;
    }
  
    .news-card-wrapper .news-card .title-date .title {
        font-size: .8rem;
    }
  
    .news-card-wrapper .news-card .title-date .date {
        font-size: .8rem;
    }
    
    .news-card-wrapper .news-card .image-section {
        max-height: 160px;
    }
    
    .footer .footer-menu-container .wrapper .footer-social-section .follow-us-title {
        font-size: 1rem;
        padding-bottom: 5%;
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo img {
      max-width: 100%;
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo a {
        max-width: calc(100% / 6 - 50px);
        @include custom-right(30px);
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo a:last-child {
        max-width: calc(100% / 6 - 50px);
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo {
      justify-content: center;
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper {
      @include p-left(45px);
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-copyright {
      text-align: left;
    }
  
    .footer .footer-menu-container {
        @include p-left(40px);
        @include p-right(40px);
    }
    // Single news 
    .header-news-card .text-contant-container .card-title h1, .header-news-card .text-contant-container .card-title .h1 {
        font-size: 1.8rem;
        line-height: 1.2em;
        padding-top: 50px;
    }
  
    .header-news-card .text-contant-container .card-title .date {
        font-size: .8rem;
        margin-bottom: 5%;
    }
        
    .btn {
        padding: 0px 5%;
        height: 38px;
    }
  
    .header-news-card .text-contant-container .social-shares h5, .header-news-card .text-contant-container .social-shares .h5 {
        font-size: .8rem;
        padding-bottom: .5rem;
    }
    
    .header-news-card .text-contant-container .social-shares ul li a {
        font-size: 1.5rem;
    }
  
    .container {
        @include p-left(40px);
        @include p-right(40px);
    }
    
    .content-footer {
        margin: 5% 0;
        padding: 2% 0;
    }
    
    .content-footer .social-shares h5, .content-footer .social-shares .h5 {
        font-size: .8rem;
        line-height: 1.2em;
        margin-bottom: 5%;
    }
    
    .content-footer .social-shares ul li a {
        font-size: 1.5rem;
    }
    
    .content-footer .social-shares ul li {
        @include custom-right(.8rem);
    }
  
    .content-footer .scroll-to-top .scroll-top {
        font-size: .8rem;
    }
    
    .container-lg, .container-md, .container-sm, .container {
      max-width: 100%;
    }
  
    .similar-news h2, .similar-news .h2 {
        font-size: 1.4rem;
        line-height: 1.2em;
        padding-bottom: 3%;
    }
  
    .btn.btn-v2 {
        height: 35px;
    }
    p {
        line-height: 1.5em;
    }
    // Single blog
    .breadcrumb-wrapper .breadcrumb ol {
        flex-wrap: wrap;
        li{
            padding-bottom: 5px;
        }
    }

    .box-content-wrapper .box-content-left {
        @include p-left(0px);
    }
    
    .box-content-wrapper {
        align-items: flex-start;
    }
    
    .back-button-container {
        margin-bottom: 5%;
    }
  
    .box-content-wrapper .box-content-left h1, .box-content-wrapper .box-content-left .h1 {
        font-size: 1.8rem;
        margin-bottom: .5rem;
    }
  
    .pb-165 {
        padding-bottom: 5%;
    }
    .similar-news {
        padding-bottom: 5%;
    }
    .bg-branding::before {
        width: 20rem;
        bottom: -254px;
        @include right(-27rem);
    }
    .teasetr-card .text-content {
        padding: 0px;
    }
    
    .teasetr-card .text-content h2, .teasetr-card .text-content .h2 {
        font-size: 1.5rem;
        line-height: 1.2em;
        margin-bottom: 5%;
    }


    // Report single
    .teasetr-card {
        margin-bottom: 5%;
        padding: 5%;
    }
  
    h3, .h3 {
        padding-bottom: 1rem;
    }
    
    .content-footer .download-container h5, .content-footer .download-container .h5 {
        font-size: .8rem;
        line-height: 1.2em;
        padding-bottom: 5px;
    }
  
    .content-footer .download-container ul li a {
        font-size: 1.5rem;
    }
    
    .footer {
        padding-top: 5%;
    }
    .hero-banner-wrapper{
        .image-container {
            min-height: 100%;
            display: flex;
        }
        img{
            display: block;
            min-height: 100%;
            height: unset;
            object-fit: cover;
        }
    }

    //wish summit page
    .hero-banner-wrapper .flex-wrapper {
        padding: 4rem 0px;
    }
    
    .hero-banner-wrapper .flex-wrapper .banner-content {
        @include p-right(10%);
    }
  
    .hero-banner-wrapper .flex-wrapper::after {
        @include right(-66%);
        bottom: -52%;
    }
        
    .hero-banner-wrapper::after {
        width: 70vw;
    }
    .summit-list {
        column-gap: 20px;
        row-gap: 20px;
        margin-bottom: 5%;
    }
    .summit-list .summit-tiles .bottom-mask {
        height: 1.8em;
        font-size: 1.6rem;
    }
  
    .hero-banner-wrapper .flex-wrapper .banner-content h2, .hero-banner-wrapper .flex-wrapper .banner-content .h2 {
        font-size: 1.8rem;
    }
    .hero-banner-wrapper {
        margin: 0 0 5% 0;
    }

    // Open wish


    .open-wish-summit .content-wrapper .sticky-sie-bar {
        width: 100%;
    }
    
    .open-wish-summit .content-wrapper .section-summit-list {
        padding-top: 5%;
    }
  
    .open-wish-summit .content-wrapper .section-summit-list .wish-summit-list .list {
        margin-bottom: 2%;
    }
    
    .open-wish-summit .content-wrapper .sticky-left {
        width: 25%;
        @include custom-right(2rem);
    }
  
    .open-wish-summit .content-wrapper .sticky-sie-bar ul li::before {
        width: 30px;
        height: 10px;
        background-size: contain;
        top: 10px;
        @include left(-30px);
    }
    
    .open-wish-summit .content-wrapper .sticky-sie-bar ul li {
        position: relative;
    }
        
    .open-wish-summit .content-wrapper .sticky-sie-bar ul li:hover::before {
        @include left(-30px);
    }
    
    .open-wish-summit .content-wrapper .section-summit-list .section-content .content-list {
        width: 100%;
    }
    .sub-title{
        iframe {
            width: 100%;
            height: 350px;
        }
    }
    .section-peoples-list {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;
    }
    .site-header .menu-content .social-share-container .soacial-share-list li a {
        max-width: calc(100% - 10px );
    }
    .divider hr {
        margin: 4% 0;
    }
    .latest-research .section-content {
        grid-template-columns: repeat(3, 1fr);
    }

    .breif-wrapper .row.pb-128 {
        margin: 0px;
    }


    .breif-wrapper {
        padding: 5% 0;
    }
    
    .pb-128 {
        padding-bottom: 5%;
    }
    
    .breif-wrapper .title {
        font-size: 1.8rem;
        line-height: 1.2em;
        padding-bottom: 2%;
    }
    .search_filters .dropdown button {
        padding: 0px 2rem;
        height: 40px;
    }
    .latest-research .section-content {
        grid-gap: 1rem;
        row-gap: 1rem;
    }


    // About us

    .about-us-page .content-container .container {
        max-width: 100%;
    }
    
    .about-us-page .content-container .container .image-colider .holder2 img:first-child {
        width: 100%;
        height: auto;
        position: relative;
        @include left(0px);
        margin-bottom: 1rem;
        object-fit: cover;
        margin: 1rem 2rem;
        border-radius: 10px;
    }
  
    .about-us-page .content-container .container .image-colider .holder2 img:last-child {
        width: 100%;
        height: auto;
        position: relative;
        object-fit: cover;
    }
        
    .about-us-page .content-container .container .image-colider .holder1 {
        margin-bottom: 1rem;
    }
  
    .about-us-page .content-container .container .title-wrapper .sub-title {
        padding: 4rem 0px;
    }
    
    .about-us-page .content-container .container .image-colider .holder2 {
        display: flex;
        width: 100%;
    }
  
    .about-us-page .content-container .container .title-wrapper h1, .about-us-page .content-container .container .title-wrapper .h1 {
        font-size: 1.8rem;
    }
    .about-us-page .content-container .container .image-colider .holder2 img:last-child {
        @include right(unset);
    }
    .about-us-page .content-container .container .image-colider .holder2 img:last-child {
        top: unset;
        margin-top: 4rem;
    }
    .about-us-page .content-container .container .image-colider .holder2 img:first-child {
        margin-bottom: 4rem;
    }
    .image-slider .title {
        font-size: 2.5rem;
        text-align: left;
        padding: 5% 0px;
    }
    .swiper-slide .slider-container .text-content {
        @include p-left(30px);

    }
    .swiper-slide .slider-container .text-content h2, .swiper-slide .slider-container .text-content .h2 {
        margin-bottom: 3%;
    }


    .blogs-list .section-content {
        column-gap: 2rem;
        row-gap: 2rem;
    }

    .highlights-slider::after {
        width: 1000px;
        height: 1000px;
        top: -100px;
        
    }
  
  
  .our-impacts .section-content .content-list p {
    padding-top: 2%;
    padding-bottom: 3%;
    font-size: 1.4rem;
    line-height: 1.4em;
  }
  
  .our-impacts .section-content {
    grid-gap: 1rem;
    padding-bottom: 1rem;
  }
  .our-impacts .view-all-holder {
    padding-bottom: 6%;
  }

    .footer .footer-partners-list {
        padding-bottom: 5%;
        flex-wrap: wrap;
    }
    .footer .footer-partners-list .partners-item {
        @include p-right(0px);
        max-width: calc(100% / 5 - 4rem);
        margin: 0px 2rem;
    }
    .footer .footer-section-title {
        font-size: 1.8rem;
        line-height: 1.2em;
        padding-bottom: 2%;
    }
  
}

@include tablet{
    .hero .hero-content .scroll-icon-gif {
        display: none;
    }
    .hero .background-main .bg-source {
        border-bottom-right-radius: 100px 100px;
    }
    .hero .hero-content .intro-title {
        font-size: 2.2rem;
    }
    .hero .background-main::before {
        border-bottom-right-radius: 100px 100px;
    }
    .highlights-slider .swiper-text-content {
        width: 300px;
    }
    .highlights-slider::after {
        @include right(-55vw);
    }
    .news-events-container .news-events-slider.mobile .slide-inner img {
        border-bottom-left-radius: 0px;
    }
    .our-impacts .view-all-holder {
        margin-top: 1rem;
    }
    .summit-list a{
        max-height: 28vw;
    }
    .news-events-container .news-events-slider.mobile .slide-inner {
        border-bottom-left-radius: 0px;
    }
    .our-impacts .section-content .content-list .image-card{
        max-height: 240px;
    }
    .news-events-container .news-events-slider .slide-inner .media-wrapper{
        border-bottom-left-radius : 0px;
    }
    .ct-block .image-container {
        height: 160px;
    }
   
    // .latest-research .section-content .content-list img{
    //     height: 160px;
    
    // }
    // .latest-research .section-content .content-list img {
    //     height: 350px;
    // }
    .latest-research .section-content .content-list img {
        height: 100%;
    }
    .latest-research.home-section  .content-list img{
        height: 350px;
    }
    .section-title .sub-title {
        margin-bottom: 0rem;
    }
    .section-title {
        padding-bottom: 2rem;
    }
    .section-title .title {
        @include p-right(0px);
    }
    .news-events-container .news-events-slider.mobile {
        @include custom-left(0);
        @include custom-right(0px);
        margin-bottom: 61px;
    }
    .innovations .section-content .content-list {
        width: 100%;
    }
    .innovations .image-card {
        max-height: 200px;
    }
    .innovations .section-content .content-list p {
        padding-top: 11px;
    }
    .innovations {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .our-impacts .section-title {
        margin-bottom: 0rem;
    }
    .section-title {
        padding-bottom: 1rem;
    }
    .our-impacts .view-all-holder {
        padding-bottom: 1rem;
    }
    .innovations .section-content {
        column-gap: 20px;
        row-gap: 20px;
    }
    .footer .footer-section-title {
        padding-bottom: 1rem;
    }
    .footer .footer-partners-list .partners-item {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0px;

    }
    .footer .footer-partners-list .partners-item img {
        max-height: 100px;
    }
    .footer .footer-partners-list {
        max-width: 90%;
        a{
            max-width: 75%;
        }
    }



    /**
    * fix
    **/


    .footer .footer-menu-container .wrapper .footer-logo-wrapper {
        justify-content: flex-start;
        padding: 0px 20px;
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo a {
        @include custom-right(20px);
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo img {
      max-width: 100%;
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo {
      width: 30%;
      height: auto;
      margin-bottom: 10px;
    }
  
    .footer .footer-menu-container .wrapper .footer-logo-wrapper {
      padding-bottom: 10px;
    }
  
    .footer-copyright.desktop-only {
        display: none ;
    }
  
    .site-header .main-navigation .nav > li a {
        font-size: 1.5rem;
    }
        
    .site-header .menu-content .social-share-container .soacial-share-list li a {
        width: 100%;
    }






    .topics-wapper .button-container {
        width: 100%;
    }
    
    .topics-wapper .button-container ul li {
        margin-bottom: 0px;
        width: 100%;
    }
  
    .topics-wapper .button-container ul {
      width: 100%;
      column-gap: 10px;
    }
    .breadcrumb-wrapper .breadcrumb ol li::after {
        width: 5px;
        @include right(5px);

    }

    .container-md, .container-sm, .container {
        max-width: 100%;
    }

    .header-news-card .text-contant-container {
        flex-direction: column;
    }
    .topics-wapper .button-container ul {
        grid-template-columns: repeat(5, 1fr);
    }
    .search-container .search-input-container .search-input {
        padding: 0px 24px;
    }
    .search-container .search-input-container .search-input {
        height: 40px;
    }
    .topics-wapper .btn {
        font-size: .6rem;
    }
  
    .search-container {
        width: 100%;
        margin-top: 1rem;
    }
  
    .btn.btn-outline.gray.bold {
        width: 100%;
    }
    .news_report_section .news-card-wrapper {
        row-gap: 1%;
    }
  
    li a {
        width: 100%;
    }
  
    .topics-wapper .button-container ul li a {
        min-width: 100%;
    }
    
    .header-news-card .text-contant-container .card-title h1, .header-news-card .text-contant-container .card-title .h1 {
      padding-top: 1rem;
    }
  
    .header-news-card .text-contant-container {
        padding-bottom: 0px;
    }
  
    .header-news-card {
      padding: 30px 0 0px;
    }
    .site-header .main-navigation .nav > li a {
        font-size: .8rem;
    }
  

    .news-card-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    .header-news-card .images-container {
        padding: 1rem 0px 2rem;
    }
    .site-header .main-navigation .search-toggle {
        padding: 10px;
    }
    .btn.btn-back {
        height: 35px;
    }

    #site-menu-header .container {
        max-width: 100% !important;
    }
    .bg-branding::before {
        width: 200px;
        height: 200px;
        bottom: 0px;
        @include right(0px);
    }
    .header-news-card.news .row > *{
        @include p-left(20px);
        @include p-right(20px);
    }

    .news-card-wrapper .news-card .image-section {
        max-height: 200px;
    }



    .content-footer {
        margin: 23px 0;
    }
  
    .similar-news h2, .similar-news .h2 {
        padding-bottom: 12px;
    }
    
    .news-card-wrapper .news-card .description {
        font-size: 1.2rem;
    }
  
    .news-card-wrapper .news-card .title-date .date {
        font-size: .8rem;
    }
  
    .similar-news {
        padding-bottom: 1rem;
    }
    .news-card-wrapper {
        grid-gap: 20px; 
    }
    #feature-cards .container {
        max-width: 95%;
    }
    
    .btn.btn-solid {
        max-width: fit-content;
        white-space: nowrap;
    }
    .our-impacts .section-content {
        grid-template-columns: repeat(2, 1fr);
    }
    .our-impacts .section-content .content-list p {
        font-size: 1rem;
    }
    .site-header .main-navigation .nav > li a {
        font-size: 1.1rem;
    }
    .topics-wapper .button-container ul li {
        max-width: fit-content;
    }
    .topics-wapper .button-container ul {
        margin-bottom: 0px;
    }
    .search_filters .dropdown button {
      height: 45p;
      margin: 1rem 0px;
      @include custom-left(2rem);
    }
  
    .search-container .search-input-container {
      max-width: 300px;
    }
  
    .summit-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .sticky-left {
        display: none;
    }

    .open-wish-summit .content-wrapper .content-right {
        width: 98%;
        margin: auto;
    }

    .open-wish-summit .content-wrapper .section-summit-list .section-content {
        grid-template-columns: repeat(2, 1fr);
    }

    
}

@include desktop{
    .news-events-container .news-events-slider .slide-inner {
        width: 55%;
    }
    .youtube-playlist .player {
        min-height: 300px;
    }
    .youtube-playlist .video-list {
        max-height: 300px;
    }
    .blogs-list .content-list .image-card {
        height: 250px;
    }
   
   
}

@include desktop{
    .swiper-slide .slider-container .text-content{
        @include custom-left(2rem);
    }
    .btn.btn-solid {
        padding: 0px 2rem;
      }
}

@include tablet{
    .about-us-page .content-container .container .title-wrapper .sub-title {
        padding: 2rem 0px;
    }
    .eyebrow-title {
        margin-top: 0rem;
    }
}

// Mobile Responsive
@include tablet{

    .site-header .menu-content .menu-items-container {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  
    .site-header .main-navigation .nav > li a {
      margin-top: 0px !important;
    }
  
  
    .social-search-container.visibility {
      border-bottom: 1px dotted #fff;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  
    .menu-items-container {
      border-bottom: 1px dotted #fff;
    }
    .social-search-container:last-child {
      border-bottom: none;
    }
  }

  

  @include desktop{
    .content-section p {
        padding: 0px 0px;
      }
      .content-section img {
        padding: 0px 0px;
      }
    .blog-detail-page .content-wrapper .container {
        padding: 0px 40px;
    }
    .blog-detail-page .back-button-container {
        margin-bottom: 1rem;
    }
    ul li {
        font-size: 1rem;
    }
    .box-content-wrapper {
        padding-bottom: 40px;
    }
    .blog-detail-page .content-footer {
        margin: 3rem 0px 2rem;
    }
    .open-wish-summit .section-title .sub-title {
        margin-bottom: 2rem;
    }


    .about-us-page .content-container .container .title-wrapper .sub-title {
      padding: 1rem 0px;
      text-align: left;
      line-height: 1.2em;
      max-width: 50%;
    }
  
    .about-us-page .content-container .container .image-colider .holder2 img:last-child {
      margin-top: 0px;
    }
    .about-us-page .content-container .container .image-colider .holder2 img:first-child {
      margin: 0px;
    }
    .about-us-page .content-container .container .image-colider .holder2 img:first-child {
      margin: 0px 1rem;
    }
  
    .image-colider img {
      max-width: 100%;
    }
  
    .image-colider{
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        margin-top: -13rem;
    }
  
    .holder1 {
        max-width: 50%;
    }
  
    .holder2 img {
        max-height: 250px;
        border-radius: 20px;
        max-width: 51%;
    }

    .holder2 {
        margin: 0px 0rem 2rem;
    }
  
    .about-us-page .content-container .container .image-colider .holder2 img:first-child {
        margin: 0px 0rem;
    }
    .holder2 img {
        margin: 0px;
    }
    .about-us-page .content-container .container .image-colider .holder2 img:first-child {
        // margin-right: ;
        @include custom-right(1rem);
    }
    .header-news-card .images-container img {
        border-radius: 20px;
        
    }
    .header-news-card .text-contant-container {
        margin-bottom: 2rem;
    }

    .topics-wapper {
        flex-direction: column-reverse;
    }
    
  
    .topics-wapper .button-container ul {
      padding-bottom: unset;
      padding-top: 20px;
    }
  
    .topics-wapper .button-container ul li {
        @include custom-right(10px);
    }
  
    .search-container {
        margin-top: 0px;
    }
    .similar-blogs .news-card:nth-child(3){
        display: none;
    }




    .site-header .main-navigation .nav > li a {
      font-size: 1.4rem;
    }
  
    .site-header .menu-content .search-container-home .search-input-container-home .search-input {
      padding: 0px 20px;
      font-size: 1rem;
    }
  
    .site-header .menu-content .social-share-container h2, .site-header .menu-content .social-share-container .h2 {
      font-size: 1rem !important;
    }
    .site-header .menu-content .social-share-container .soacial-share-list li a {
      font-size: 1rem;
    }
  
    .site-header .main-navigation .nav > li .dropdown .dropdown-toggle::after {
        font-size: 1rem;
    }
    
    .site-header .menu-content .social-share-container h2, .site-header .menu-content .social-share-container .h2 {
      font-family: $bold-font;
    }
  
    .site-header .menu-content .search-container-home .search-icon-btn {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
    }

    .site-header .menu-content .search-container-home h2, .site-header .menu-content .search-container-home .h2{
        font-family: $bold-font;
    }
  
    .site-header .menu-content .search-container-home h2, .site-header .menu-content .search-container-home .h2 {
        font-size: 1.4rem;
    }

    .site-header .menu-content .social-share-container .soacial-share-list{
        // margin-right: -10px;
        @include custom-right(-10px);
    }

    nav.footer-links-section {
        margin-bottom: 3rem;
    }
    .footer .footer-menu-container .wrapper .footer-social-section .social-links{
        grid-template-columns: repeat(2, 1fr);
    }

    .footer .footer-menu-container .wrapper .footer-links-section ul{
        grid-template-columns: repeat(2, 1fr);
    }
  }

  @include desktop{
    .speakers-modal .modal-header .btn-close {
        padding: 10px;
        font-size: 1.5rem;
    }
    .speakers-modal .modal-body h3, .speakers-modal .modal-body .h3 {
        font-size: 1.8rem;
        line-height: 1.2em;
        padding-bottom: 0px;
    }
    .speakers-modal .modal-body .bio {
        font-size: 1rem;
        line-height: 1.2em;
        padding-bottom: 1rem;
    }
    .open-wish-summit .content-wrapper .content-right {
        width: 100%;
        padding: 0px 40px;
    }
    .open-wish-summit .content-wrapper .sticky-left {
        display: none;
    }
    
    .latest-research.home-section .section-content .content-list img {
        height: 380px;
    }
    .latest-research .section-content {
        grid-template-columns: repeat(4, 1fr);
    }

  
    .highlights-slider .swiper-text-content .highlights-content-list .highlights-item .eyebrow-text {
        font-size: 1rem;
        line-height: 1.2em;
        margin-bottom: 20px;
    }

    .highlights-slider .swiper-text-content .highlights-content-list .highlights-item h2, .highlights-slider .swiper-text-content .highlights-content-list .highlights-item .h2 {
        font-size: 1.8rem;
        line-height: 1.2em;
        margin-bottom: 1rem;
    }
    .btn.btn-solid {
        padding: 0px 1rem;
    }
    .btn.btn-solid {
        height: 40px;
    }
    .highlights-slider .btn-solid {
        margin-top: 0rem;
    }
    .hero .hero-content .intro-title {
        font-size: 2.2rem;
        line-height: 1.5em;
    }
    .highlights-slider .swiper {
        height: 450px;
    }
    .highlights-slider .globe-mask::after {
        width: 300px;
        height: 300px;
    }

    .highlights-slider .swiper-text-content .highlights-content-list .highlights-item p {
        line-height: 1.4em;
    }


  }


  @include tablet{
   
    .footer .footer-menu-container .wrapper .footer-logo-wrapper {
      @include p-left(20px);
    }
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo a {
        max-width: 150px;
        
    }
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo a:last-child {
        max-width: 150px;
        
    }
 
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo {
      width: 100% !important;
    }
    .footer .footer-menu-container .wrapper .footer-logo-wrapper .footer-logo {
        width: 100%;
        margin-bottom: 10px;
    }
    .footer-logo-wrapper > div:nth-child(1) {
        width: 100%;
    }
    .mobile-copy .footer-copyright {
        padding-bottom: 1rem;
    }


    .footer .footer-partners-list .partners-item {
      @include p-left(0px);
    }
  
    .footer .footer-partners-list .partners-item {
      max-width: calc(20% - 2rem);
      margin: 0px 1rem;
    }

    .section-title .title.bg-pulse .text {
        line-height: 1.2em;
    }
    // .latest-research .section-title .title.bg-pulse .pulse-img {
    //     margin-bottom: 5px;
    // }
    .latest-research.home-section .section-content .content-list img {
        height: 300px;
    }

    .section-title .sub-title {
      font-size: 1rem;
      line-height: 1.2em;
    }

    .section-title .title {
        padding-bottom: 12px;
    }

    .innovations {
      padding-top: 8%;
      padding-bottom: 8%;
    }
  
 
    .latest-research.home-section .section-content .content-list img {
        height: 250px;
    }
    .section-title .title.bg-pulse .text {
        margin-bottom: 0.5rem;
    }

   
    .highlights-slider::after {
        width: 105vw;
        height: 105vw;
    }
    .highlights-slider::after {
        @include right(-25vw);
        top: -10vw;
    }
    .highlights-slider .swiper-text-content .highlights-content-list .highlights-item p {
        line-height: 1.2em;
    }

 
}


@include desktop{
    ol li , ul li {
        font-size: 1rem;
      }
}

@include tablet{
    .bg-globe-rit::before {
        display:none  !important;
    }
    .highlights-slider .swiper-text-content .highlights-content-list .highlights-item h2, .highlights-slider .swiper-text-content .highlights-content-list .highlights-item .h2 {
        font-size: 1.4rem;
    }
}