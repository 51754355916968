.our-impacts-page {
  .section-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 80px;
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 40px;
    }
    & .content-list {
      width: 499px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }
  .our-impacts-wrapper {
    .content-list {
      .image-card {
        overflow: hidden;
        transition: 0.4s ease;
        border-radius: 10px;
        img{
          object-fit: cover;
        }
      }
      &:hover img {
        -webkit-transform: scale(1.03);
        transform: scale(1.03);
      }
      img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
      }
      .project-title-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 43px 0 30px 0;
        color: $spanish-gray;
        @include media-breakpoint-down(lg) {
          padding: 22px 0 18px 0;
        }
        .project-title {
          font-family: "DinProBold";
          font-size: 18px;
          line-height: 40px;
          @include media-breakpoint-down(lg) {
            font-size: 12px;
            line-height: 20px;
          }
        }
        .project-date {
          font-size: 18px;
          line-height: 22px;
          @include media-breakpoint-down(lg) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
      p {
        line-height: 31px;
        font-size: 26px;
        font-family: "DinNextLTRegular";
        margin-bottom: 54px;
        @include media-breakpoint-down(lg) {
          line-height: 18px;
          font-size: 13px;
          margin-bottom: 27px;
        }
      }
    }
  }
}


.our-impacts .section-content .content-list .image-card img {
  object-fit: cover;
}