.footer {
  padding-top: 90px;
  background: $linear-gradient;
  @include media-breakpoint-down(lg) {
    padding-top: 45px;
  }
  .footer-section-title {
    font-size: 60px;
    font-family: $bold-font;
    line-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    padding-bottom: 90px;
    @include media-breakpoint-down(lg) {
      padding-bottom: 45px;
      font-size: 20px;
    }
  }
  .footer-partners-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    margin: 0 auto;
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      padding-bottom: 42px;
      max-width: 300px;
      margin: 0 auto;
    }
    .partners-item {
      @include p-right(5%);
     
      @include media-breakpoint-down(lg) {
        @include p-right(26px);
      }
      &:last-child {
        @include p-right(0);
      }

      img {
        @include desktop-xxl{
          max-height: 100px;
          object-fit: contain;
        }
        @include desktop{
          max-height: 94px;
        }
      }
     
    }
  }
  .footer-menu-container {
    @include p-left(90px);
    @include p-right(90px);
    @include media-breakpoint-down(lg) {
      @include p-left(0);
      @include p-right(0);
    }
    .wrapper {
      background-color: $white;
      border-radius: 80px 80px 0px 0px;
      padding-top: 44px;
      padding-bottom: 68px;
      @include p-left(6%);
      @include p-right(6%);

      @media (min-width: 992px) and (max-width: 1680px) {
        @include p-left(20px);
        @include p-right(20px);
      }
      @media (min-width: 1400px) and (max-width: 1780px) {
        @include p-left(60px);
        @include p-right(60px);
      }

      @include media-breakpoint-down(lg) {
        border-radius: 50px 50px 0px 0px;
        padding-top: 22px;
        padding-bottom: 34px;
        @include p-left(20px);
        @include p-right(20px);
      }
      .footer-logo-wrapper {
        display: flex;
        justify-content: center;
        @media (min-width: 992px) and (max-width: 1680px) {
          padding-bottom: 30px;
        }
        @include media-breakpoint-down(lg) {
          padding-bottom: 30px;
        }

        .footer-logo {
          display: flex;
          // width: 330px;
          height: auto;
          justify-content: space-between;
          margin-bottom: 1rem;
          align-items: flex-end;
          width: auto;
          @media (min-width: 992px) and (max-width: 1680px) {
            // margin-bottom: 30px;
            margin-bottom: 15px;
          }
          @include media-breakpoint-down(lg) {
            width: 165px;
            height: 48px;
            margin-bottom: 30px;
          }
          @include desktop{
            justify-content: center;
            
          }
          @include mobile{
            width: 225px;
            height: 50px;
            margin-bottom: 0px;
            
          }
          a{
            max-width: 35%;
            &:last-child {
              max-width: 100%;
            }
          }
          img {
         
            @include desktop{
              max-width: 24%;
            }
            @include mobile{
              max-width: 50%;
              width: 100%;
              margin: 3px 1rem;
              margin: 0px 1rem;

            }
          }
        }
        .footer-copyright {
          font-size:  1rem;
          line-height: 1.2em;
          margin-top: 3.5rem;
          @media (min-width: 992px) and (max-width: 1680px) {
            text-align: center;
            font-size: 15px;
          }

          @include media-breakpoint-down(lg) {
            font-size: 12px;
            text-align: center;
          }
        }
      }

      .footer-links-section {
        padding: 0 69px 0 100px;

        @media (min-width: 992px) and (max-width: 1680px) {
          padding: 0 30px 0 50px;
        }

        @include media-breakpoint-down(lg) {
          padding: 0;
        }
        ul {
          padding: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 60px;
          row-gap: 20px;
          @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(1, 1fr);
            column-gap: 0;
            row-gap: 10px;
            padding: 0 20px;
          }
          li {
            a {
              font-family: $light-font;
              font-size: 1.6rem;
              color: #575756;
              line-height: 36px;
              padding-bottom: 13px;
              border-bottom: 1px solid $may-green;
              border-bottom-style: dotted;
              @include media-breakpoint-down(lg) {
                font-size: 16px;
                padding-bottom: 0;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 18px;
                padding-bottom: 0;
              }
              @media (min-width: 1400px) and (max-width: 1950px) {
                font-size: 1.5rem;
                padding-bottom: 0;
              }

              &:hover , &.active {
                color: $may-green;
              }
            }
          }
        }
      }
      .footer-social-section {
        @include media-breakpoint-down(lg) {
          padding: 0 20px;
        }
        .follow-us-title {
          font-size: 21px;
          line-height: 13px;
          font-family: $bold-font;
          padding-bottom: 40px;
          @include media-breakpoint-down(lg) {
            font-size: 12px;
            line-height: 13px;
            padding-bottom: 20px;
          }
        }
        .social-links {
          padding: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 30px;
          row-gap: 20px;
          @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(1, 1fr);
            column-gap: 0;
            row-gap: 10px;
          }
          .links {
            a {
              font-size: 24px;
              line-height: 36px;
              color: $may-green;
              padding-bottom: 13px;
              border-bottom: 1px dotted $may-green;
              border-bottom-style: dotted;
              text-decoration: none;
              @include media-breakpoint-down(lg) {
                font-size: 16px;
                padding-bottom: 0;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 18px;
                padding-bottom: 0;
              }

              @media (min-width: 1400px) and (max-width: 1950px) {
                font-size: 16px;
                padding-bottom: 0;
              }
              @media (min-width: 1330px) and (max-width: 1500px) {
                font-size: 15px;
              }
              &:hover {
                color: $may-green;
                span{
                  text-decoration: underline;
                }
              }
              i {
                @include p-right(20px);
              }
            }
          }
        }
      }
    }
  }
}


