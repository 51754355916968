#partner-with-page{

    .registraction-section {
        padding: 0% 0px 4%;
    }
    .registraction-section .section-title {
        max-width: 350px;
    }
    .registraction-section .section-title .title {
        line-height: 1.4em;
    }
    .registraction-section .img-container{
        margin-top: auto;
    }
    .registraction-section  .content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
  
    .why-attend .topics {
        grid-template-columns: repeat(4, 1fr);
    }
    .why-attend p {
        font-size: 1.35rem;
    }


    .why-attend  .why-attend-topics h3 {
        font-size: 5rem;
        margin: 0px;
        padding: 0px;
    }
    
    .why-attend  .why-attend-topics h5 {
        font-size: 1.5rem;
        @include desktop-xxl{
            font-size: 1.5rem;
          }
          @include mobile{
              font-size: 1.4rem;
          }
    }
    
 
  

  
}

@include  desktop-xxl{

    #partner-with-page .registraction-section .form-container {
        @include custom-left(0px);
    }
    #partner-with-page .registraction-section .img-container {
        margin-top: 5%;
    }
}
@include desktop{
    #partner-with-page .registraction-section {
        padding: 0% 0px 6%;
    }
    .why-attend .container::after {
        @include right(3.5rem)
    }
    #partner-with-page .why-attend .why-attend-topics h3, #partner-with-page .why-attend .why-attend-topics .h3 {
        font-size: 3.5rem;
    }
    
}


@include tablet{
    #partner-with-page .registraction-section {
        padding: 0% 0px 6%;
    }
    #partner-with-page .why-attend .why-attend-topics h3, #partner-with-page .why-attend .why-attend-topics .h3 {
        font-size: 3.5rem;
    }
  

    #partner-with-page .why-attend .container::after {
        width: 80px;
        height: 100px;
        top: -4rem;
        @include right(2.5rem);
    }
    #partner-with-page .why-attend .topics {
        grid-template-columns: repeat(2, 1fr);
    }
    .why-attend .container::after {
        width: 80px;
        height: 160px;
        top: -4rem;
        @include right(0.5rem);
    }
    #registration-page .about-wish-section .content img {
        border-radius: 40px 0px 40px 0px;
    }
    .why-attend {
        padding: 5% 0px;
    }
}
@include mobile{
    #partner-with-page .why-attend .container::after {
        @include right(-1.5rem);
        top: -6rem;
    }
    #partner-with-page .why-attend .why-attend-topics h3, #partner-with-page .why-attend .why-attend-topics .h3 {
        font-size: 3rem;
    }
    #partner-with-page .why-attend .why-attend-topics h5, #partner-with-page .why-attend .why-attend-topics .h5 {
        font-size: 1rem;
    }
    #partner-with-page .why-attend p {
        font-size: 1.2rem;
    }
    .why-attend .container::after {
        @include right(0rem);
    }
    #registration-page .oppertunities-section .count-container {
        @include custom-left(0px);
    }
}
