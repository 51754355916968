.our-impacts {
  padding-top: 122px;
  @include media-breakpoint-down(lg) {
    padding-top: 61px;
    padding-bottom: 51px;
  }
  .view-all-holder {
    padding-bottom: 80px;
    @include media-breakpoint-down(lg) {
      padding-bottom: 40px;
    }
  }
  .section-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 75px;
    margin: 0 auto;
    padding-bottom: 80px;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
      grid-gap: 30px;
      padding-bottom: 40px;
    }
    & .content-list {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      .image-card {
        overflow: hidden;
        transition: 0.4s ease;
        border-radius: 10px;
        margin-bottom: 33px;
        @include media-breakpoint-down(lg) {
          margin-bottom: 15px;
        }
      }
   

      &:hover img {
        -webkit-transform: scale(1.03);
        transform: scale(1.03);
      }
      img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
      }
      p {
        padding-top: 2%;
        padding-bottom: 3%;
        font-size: 1.5rem;
        line-height: 1.3em;
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          line-height: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        i {
          color: $may-green;
          font-size: 17px;
          @include media-breakpoint-down(lg) {
            font-size: 10px;
          }
        }
      }
    }
    .project-title-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .project-title {
        font-family: "DinProBold";
        font-size: 18px;
        line-height: 40px;
        color: $spanish-gray;
        @include media-breakpoint-down(lg) {
          font-size: 9px;
          line-height: 20px;
        }
      }
      .project-date {
        font-size: 18px;
        line-height: 22px;
        color: $spanish-gray;
        @include media-breakpoint-down(lg) {
          font-size: 9px;
          line-height: 20px;
        }
      }
    }
  }
}


.our-impacts{
  padding-top: 5%;
}
