.open-wish-summit {
  .page-titles-wrapper {
    padding-bottom: 5%;
    @include media-breakpoint-down(lg) {
      padding-bottom: 50px;
    }
  }
  .content-wrapper {
    position: relative;
    display: flex;
    @include media-breakpoint-down(lg) {
      display: block;
      @include p-left(30px);
      @include p-right( 30px );
    }
    .sticky-left {
      width: 30%;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    .content-right {
      width: 60%;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    .sticky-sie-bar {
      background: $electric-blue;
      border-bottom-right-radius: 95px;
      border-top-right-radius: 12px;
      padding: 40px 0;
      display: flex;
      justify-content: center;
      width: 400px;
      position: sticky;
      @include left(0);
      top: 80px;
      @include custom-right(150px);
      margin-bottom: 150px;
      @include media-breakpoint-down(lg) {
        position: relative;
        padding: 20px 0;
        width: 100%;
        top: 0;
        @include left(0);
        border-radius: 0;
        margin-bottom: 30px;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          text-align: center;
          margin-bottom: 33px;
          color: $white;
          font-size: 24px;
          text-align: justify;
          @include media-breakpoint-down(lg) {
            margin-bottom: 16px;
            font-size: 16px;
          }
          cursor: pointer;
          a {
            color: inherit;
            text-decoration: none;
          }

          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: "";
            transition: transform 500ms;
            content: "";
            background: url("/assets/images/pluse_small.png");
            width: 80px;
            height: 40px;
            display: block;
            background-repeat: no-repeat;
            position: absolute;
            opacity: 0;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            @include left(20px);
          }
          &:hover {
            & span {
              transform: translateX(20px);
              display: block;
            }
            &::before {
              @include left(30px);
              opacity: 1;
            }
          }
        }
      }
    }

    .section-summit-list {
      padding-top: 150px;
      @include media-breakpoint-down(lg) {
        padding-top: 75px;
      }
      &:last-child {
        padding-bottom: 150px;
        @include media-breakpoint-down(lg) {
          padding-bottom: 75px;
        }
      }
      &:first-child {
        padding-top: 0;
      }
      .summit-title {
        font-size: 3rem;
        line-height: 1.2em;
        color: $electric-blue;
        font-family: $light-font;
        margin-bottom: 3%;
        @include media-breakpoint-down(lg) {
          font-size: 30px;
          line-height: 21px;
          margin-bottom: 25px;
        }
      }
      .wish-summit-list {
        .list {
          a {
            font-size: 1.45rem;
            line-height: 1.2em;
            font-family: $medium-font;
            color: $davy-grey;
            @include media-breakpoint-down(lg) {
              font-size: 16px;
              line-height: 18px;
            }
            &:hover {
              color: $may-green;
            }
          }
          margin-bottom: 40px;
          @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
          }
        }
        p{
          font-size: 1.35rem;
        }
      }
      .section-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        row-gap: 2rem;
        @include media-breakpoint-down(lg) {
          grid-template-columns: repeat(1, 1fr);
          row-gap: 20px;
        }
        & .content-list {
          width: 100%;
          a {
            flex-direction: column;
            align-items: start;
            flex-direction: column;
            &:hover {
              color: $teal-blue;
            }
          }
          .image-card {
            overflow: hidden;
            border-radius: 10px;
            height: 250px;
            background: #cbe6f5;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover img {
              -webkit-transform: scale(1.08);
              transform: scale(1.08);
            }
            img {
              -webkit-transition: 0.4s ease;
              transition: 0.4s ease;
              object-fit: cover;
            }
          }

          p {
            padding-top: 41px;
            color: #575756;
            @include media-breakpoint-down(lg) {
              padding-top: 20px;
            }
            i {
              color: $may-green;
              font-size: 17px;
              @include media-breakpoint-down(lg) {
                font-size: 10px;
              }
            }
          }
          &:hover{
            p{
              color: $may-green;
            }
          }
        }
      }
    }
  }
}

.section-peoples-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  column-gap: 42px;
  row-gap: 40px;
  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    column-gap: 22px;
    row-gap: 40px;
  }
  .people-card {
    text-align: center;
    cursor: pointer;
    .image-holder {
      overflow: hidden;
      border-radius: 100%;
      &:hover img {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
      }
      img {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        height: 100%;
        object-fit: cover;
      }
    }

    h4 {
      font-size: 1.45rem;
      line-height: 1.2em;
      font-family: $medium-font;
      margin: 39px 0 10px 0;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
        line-height: 30px;
        margin: 21px 0 10px 0;
      }
    }
    &:hover{
      h4 {
      text-decoration: underline ;
      }
    }
    .bio {
      font-size: 1.35rem;
      line-height: 1.2em;
      font-family: $light-font;
      @include media-breakpoint-down(lg) {
        font-size: 13px;
        line-height: 12px;
      }
    }
    &:hover {
      h4 {
        color: $may-green;
      }
    }
  }
}

.speakers-modal {
  .modal-xl {
    max-width: 1600px;
    @media (max-width: 1680px) {
      max-width: 1500px;
    }
  }
  .modal-header {
    border: none;
    .btn-close {
      border: 0;
      background: none;
      padding: 30px;
      font-size: 33px;
      color: $davy-grey;
    }
  }
  .modal-body {
    padding: 0 5% 4% 5%;
    .author-img {
      img {
        border-radius: 12px;
      }
    }
    h3 {
      font-size: 32px;
      line-height: 42px;
      font-family: $light-font;
      color: $may-green;
      padding-bottom: 0px;
    }
    .bio {
      font-size: 20px;
      line-height: 40px;
      font-family: $light-font;
      color: $electric-blue;
      padding-bottom: 35px;
    }
  }
}






// Youtube

.youtube-playlist {
  margin-bottom: 4rem;
  margin: auto 4% 4rem;
  .video-player {
    display: flex;
  }
  .player {
    flex: 1 1 0;
    min-height: 600px;
    @include custom-right(2rem);
  }
  img {
    width: 120px;
    width: 100%;
    height: 80px;
    @include custom-right(1rem);
    background: #cfcfcf;
  }
  
  .video-list {
    max-height: 600px;
    overflow: scroll;
    max-width: 30%;
    @include p-right( 3rem );
    border-left: 1px solid #000;
    max-width: 400px;
    li {
      cursor: pointer;
      /* flex-direction: column; */
      display: flex;
      align-items: center;
    }
  } 

  .opener{
    width: 100%;
    height: 100%;
  }
  
}







.youtube-playlist {
  margin: auto 0px 4rem;
}

.youtube-playlist img {
  max-width: 120px;
  width: 100%;
}

.youtube-playlist .video-list li {
  margin-bottom: 1rem;
}

.latest-research .section-content .content-list img {
object-fit: cover;
}


